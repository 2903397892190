export class DeliveryDetailConst {
    public static readonly OWNER_ID           = "オーナーID";
    public static readonly INDICATE_ID        = "指示ID";
    public static readonly RECEPTION_DATETIME = "解析受付日時";
    public static readonly STATUS             = "指示ID進捗";
    public static readonly DELIVERY_PERSONNEL = "納品担当者";
    public static readonly DELIVERY_COMMENT   = "納品コメント";
    public static readonly UPDATE_BTN         = "更新";

    public static readonly FARM_ID_LISTHEADER              = "圃場ID";
    public static readonly RECEPTION_DATETIME_LISTHEADER   = "検品受付日時";
    public static readonly INSPECTION_STATUS_LISTHEADER    = "圃場ID進捗";
    public static readonly INSPECTION_RESULT_LISTHEADER    = "検品結果";
    public static readonly INSPECTION_PERSONNEL_LISTHEADER = "検品担当者";

    public static readonly DELIVERY_TARGET = "納品対象";
    public static readonly DELIVERY_ENABLE = "納品可否";
    public static readonly DELIVERY_BTN    = "納品";
    public static readonly STOP_BTN        = "中止";
}
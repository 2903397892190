import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProgressModalConst } from '../../constant/progress-modal-const';

@Component({
    selector: 'app-progress-modal',
    templateUrl: './progress-modal.component.html',
    styleUrls: ['./progress-modal.component.css']
})
export class ProgressModalComponent implements OnInit {
    progressModalConst: ProgressModalConst;
    message: string;
    errorMessage: string;
    currentCount: number;
    totalCount: number;
    okBtnCtl: boolean;
    crossBtnCtl: boolean;
    @Output() progressEventKind = new EventEmitter<String>();

    constructor() {
        console.log("    ProgressModalComponent---------------constructor() ----------------------");
        this.progressModalConst = ProgressModalConst;
    }

    ngOnInit() {
        console.log("    ProgressModalComponent---------------ngOnInit() ----------------------");
        this.okBtnCtl = true;
        this.crossBtnCtl = true;
    }
    clickOk(eventKind: string) {
        console.log("    ProgressModalComponent---------------clickOk() ----------------------");
        this.progressEventKind.emit(eventKind);
        return;
    }
    setScreenStatus(message: string, currentCount: number, totalCount: number, okBtnCtl: boolean, isError: boolean ) {
        console.log("    ProgressModalComponent---------------setScreenStatus() ----------------");

        this.okBtnCtl = okBtnCtl;
        this.crossBtnCtl = okBtnCtl;
        this.currentCount = currentCount;
        this.totalCount = totalCount;
        this.errorMessage = "";
        this.message = "";
        if(isError) {
            this.errorMessage = message;
        } else {
            this.message = message;
        }
        return;
    }
}

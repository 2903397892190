import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonConst } from '../../constant/common-const';
import { MessageConst } from '../../constant/message-const';
import { DeliveryListConst } from '../../constant/delivery-list-const';
import { DeliveryService } from '../../service/delivery.service';
import { DeliveryList } from '../../model/delivery-list';
import { DeliveryCondition } from '../../model/delivery-condition';
import { DeliveryDetailComponent } from '../delivery-detail/delivery-detail.component';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { ProgressModalComponent } from '../progress-modal/progress-modal.component';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { DeliveryDetail } from '../../model/delivery-detail';
import { DeliveryDetailList } from '../../model/delivery-detail-list';
import { ExecuteResult } from '../../model/execute-result';
import { Observable } from 'rxjs';
import { InspectionService } from '../../service/inspection.service';
import { InspectionList } from '../../model/inspection-list';
import { InspectionCondition } from '../../model/inspection-condition';
import { InspectionDetail } from '../../model/inspection-detail';
import { CognitoService } from '../../service/cognito.service';

declare var $;

@Component({
    selector: 'app-delivery-list',
    templateUrl: './delivery-list.component.html',
    styleUrls: ['./delivery-list.component.css']
})
export class DeliveryListComponent implements OnInit {

    commonConst: CommonConst;
    deliveryListConst: DeliveryListConst;
    deliveryCondition: DeliveryCondition;
    fixDeliveryCondition: DeliveryCondition;
    deliveryLists: DeliveryList[];
    messageList: string[];
    selectedDeliveryList: DeliveryList
    deliveryDetail: DeliveryDetail;
    deliveryDetailLists:DeliveryDetailList[];
    inspectionCondition: InspectionCondition;
    inspectionLists: InspectionList[];

    private getDeliverysResult: ExecuteResult; // deliveryList
    private getDeliverysTotalCountResult: ExecuteResult; // deliveryListTotalCount
    private getDeliveryDetailResult: ExecuteResult; // deliveryDetail
    private getDeliveryDetailListResult: ExecuteResult; // deliveryDetailFarmsList
    private updateDeliveryDetailResult: ExecuteResult; // update deliveryDetail
    private executeDeliveryResult: ExecuteResult; // execute delivery
    private getInspectionsResult: ExecuteResult; // inspectionList

    @ViewChild(DeliveryDetailComponent)
    protected deliveryDetailComponent: DeliveryDetailComponent;
    @ViewChild(SuccessModalComponent)
    protected successModalComponent: SuccessModalComponent;
    @ViewChild(ErrorModalComponent)
    protected errorModalComponent: ErrorModalComponent;
    @ViewChild(ProgressModalComponent)
    protected progressModalComponent: ProgressModalComponent;
    @ViewChild(ConfirmModalComponent)
    protected confirmModalComponent: ConfirmModalComponent;

    totalCount: number;
    serchBtnCtl: boolean;
    prevBtnCtl: boolean;
    nextBtnCtl: boolean;
    eventKind: string;
    loopStop: boolean;
    loopNum: number;
    deliveryErrorExist: boolean;
    username: string;

    constructor(private router: Router, private deliveryService: DeliveryService, private inspectionService: InspectionService, private cognito: CognitoService) {
        console.log("DeliveryListComponent---------------constructor() ----------------------");
        this.commonConst = CommonConst;
        this.deliveryListConst = DeliveryListConst;
        this.deliveryCondition = new DeliveryCondition();
        this.cognito.isAuthenticated()
        .then((res) => {
            this.username = res["idToken"]["payload"]["cognito:username"];
        })
        .catch((err) => {
            console.log(err);
            this.router.navigate(['login']);
        });
    }

    ngOnInit() {
        console.log("DeliveryListComponent---------------ngOnInit() ----------------------");
        // initial condition value
        this.clearCondition();
        this.prevBtnCtl = true;
        this.nextBtnCtl = false;
        // initial search
        this.getDeliveryLists();
        this.loopNum = 0;
    }

    // tabSelect
    pageSelect() {
        console.log("DeliveryListComponent---------------pageSelect() ----------------------");
        this.router.navigate(['inspection']);
        return;
    }

    getDeliveryLists() {
        console.log("DeliveryListComponent---------------getDeliveryLists() ----------------------");
        this.showLoadingModal();
        this.messageList = [""];
        // input check
        if(this.deliveryCondition.receptionDateFrom != "") {
            if(!this.deliveryCondition.receptionDateFrom.match(/^\d{4}-\d{2}-\d{2}$/)) {
                this.messageList.push(MessageConst.DELIVERYLIST_INVALIDFORMT_RECEPTDATE_FROM);
            }
        }
        if(this.deliveryCondition.receptionDateTo != "") {
            if(!this.deliveryCondition.receptionDateTo.match(/^\d{4}-\d{2}-\d{2}$/)) {
                this.messageList.push(MessageConst.DELIVERYLIST_INVALIDFORMT_RECEPTDATE_TO);
            }
        }
        if(this.messageList.length > 1) {
            this.hideLoadingModal();
            return;
        }

        this.fixDeliveryCondition = Object.assign({}, this.deliveryCondition);    
        this.fixDeliveryCondition.startLineNumber = 1;
        this.fixDeliveryCondition.endLineNumber = 20;
        this.fixDeliveryCondition.page = 1;

        // get list
        this.deliveryService.getDeliverys(this.deliveryCondition).subscribe(
            data => {
                this.getDeliverysResult = data;
                if(this.getDeliverysResult.result) {
                    this.deliveryLists = this.getDeliverysResult.resultArray;

                    this.deliveryService.getDeliverysTotalCount(this.deliveryCondition).subscribe(
                        data => {
                          console.log("DeliveryListComponent-------getDeliverysTotalCount() success---------");
                          this.getDeliverysTotalCountResult = data; 
                          if(this.getDeliverysTotalCountResult.result) {
                              this.totalCount = +this.getDeliverysTotalCountResult.totalCount;

                              this.fixDeliveryCondition.startLineNumber = 1;
                              this.fixDeliveryCondition.endLineNumber = 20;
                          
                              if(this.totalCount < 21) {
                                  this.nextBtnCtl = true;
                                  this.fixDeliveryCondition.endLineNumber = this.totalCount;
                              }  
                              if(this.totalCount > 20) {
                                  this.nextBtnCtl = false;
                                  let arrayCount = Math.ceil(this.totalCount/20); 
                                  this.fixDeliveryCondition.instructionIdKeyList = new Array(arrayCount)             
                                  this.fixDeliveryCondition.instructionIdKeyList[0] = this.getDeliverysResult.resultArray[19].instruction_id;
                                  console.log("instruction_id_key = " + this.fixDeliveryCondition.instructionIdKeyList[0]);
                              }
                              if(this.totalCount == 0) {
                                  this.nextBtnCtl = true;
                                  this.prevBtnCtl = true;
                                  this.fixDeliveryCondition.startLineNumber = this.totalCount;
                                  this.fixDeliveryCondition.endLineNumber = this.totalCount;
                              }
                              this.serchBtnCtl = false;
                              this.hideLoadingModal();
                              return;
                          } else {
                              this.hideLoadingModal();
                              this.errorModalComponent.setScreenStatus(MessageConst.MODAL_SEARCH_TOTALCOUNT_NG);
                              this.showErrorModal();
                              return;
                          }
                      },
                      error => {
                          console.log("DeliveryListComponent------------getDeliverysTotalCount() error-------------");
                          console.log(error);
                          this.hideLoadingModal();
                          this.errorModalComponent.setScreenStatus(MessageConst.MODAL_SEARCH_TOTALCOUNT_NG);
                          this.showErrorModal();
                          return;
                      }      
                    );// getDeliverysTotalCount subscribe end
                } else {
                    console.log(this.getDeliverysResult.message);
                    this.hideLoadingModal();
                    this.errorModalComponent.setScreenStatus(MessageConst.MODAL_SEARCH_NG);
                    this.showErrorModal();
                    return;
                }
            },
            error => {
                console.log("DeliveryListComponent------------getDeliverys() error-------------");
                this.hideLoadingModal();
                this.errorModalComponent.setScreenStatus(MessageConst.MODAL_SEARCH_NG);
                this.showErrorModal();
                return;
            }
        ); // getDeliverys subscribe end
    }

    getDeliveryPageLists(){
        console.log("DeliveryListComponent------------getDeliveryPageLists() -------------");
        // get pagelist
        this.deliveryService.getPageDeliverys(this.fixDeliveryCondition).subscribe(
            data => {
                this.getDeliverysResult = data;
                if(this.getDeliverysResult.result) {
                    this.deliveryLists = this.getDeliverysResult.resultArray;
                    if(this.getDeliverysResult.resultArray.length == 20) {
                        this.fixDeliveryCondition.instructionIdKeyList[this.fixDeliveryCondition.page - 1] = this.getDeliverysResult.resultArray[19].instruction_id;
                        console.log("★★instruction_id_key = " + this.fixDeliveryCondition.instructionIdKeyList[this.fixDeliveryCondition.page - 1]);
                    }
                    this.hideLoadingModal();
                    return;

                } else {
                    console.log(this.getDeliverysResult.message);
                    this.hideLoadingModal();
                    this.errorModalComponent.setScreenStatus(MessageConst.MODAL_SEARCH_NG);
                    this.showErrorModal();
                    return;
                }
            },
            error => {
                console.log("DeliveryListComponent------------getDeliveryPageLists() error-------------");
                console.log(error);
                this.hideLoadingModal();
                this.errorModalComponent.setScreenStatus(MessageConst.MODAL_SEARCH_NG);
                this.showErrorModal();
                return;
            }
        );
    }

    pageNext() {
        console.log("DeliveryListComponent---------------pageNext() ----------------------");
        this.showLoadingModal();
        this.fixDeliveryCondition.start_instruction_id = this.fixDeliveryCondition.instructionIdKeyList[this.fixDeliveryCondition.page-1];
        this.fixDeliveryCondition.page = this.fixDeliveryCondition.page + 1;
        this.fixDeliveryCondition.startLineNumber = this.fixDeliveryCondition.startLineNumber + 20;
        this.fixDeliveryCondition.endLineNumber = this.fixDeliveryCondition.endLineNumber + 20;

        this.prevBtnCtl = false;

        let diffCount = this.totalCount - this.fixDeliveryCondition.endLineNumber;
        if( diffCount <= 0 ) {
            this.nextBtnCtl = true;
            this.fixDeliveryCondition.endLineNumber = this.fixDeliveryCondition.endLineNumber + diffCount;
        }
        this.getDeliveryPageLists();
        return;
    }

    pagePrev(){
        console.log("DeliveryListComponent---------------pagePrev() ----------------------");
        this.showLoadingModal();
        this.fixDeliveryCondition.start_instruction_id = "";
        if (3 <= this.fixDeliveryCondition.page ) {
            this.fixDeliveryCondition.start_instruction_id = this.fixDeliveryCondition.instructionIdKeyList[this.fixDeliveryCondition.page-3];
        }
        this.fixDeliveryCondition.page = this.fixDeliveryCondition.page - 1;
        this.fixDeliveryCondition.startLineNumber = this.fixDeliveryCondition.startLineNumber - 20;
        this.fixDeliveryCondition.endLineNumber = this.fixDeliveryCondition.startLineNumber + 19;

        this.nextBtnCtl = false;      
        if(this.fixDeliveryCondition.startLineNumber == 1) {
            this.prevBtnCtl = true;
        } else {
            this.prevBtnCtl = false;      
        }
        this.getDeliveryPageLists();
        return;
    }

    clearCondition() {
        console.log("DeliveryListComponent---------------clearCondition() ----------------------");
        // initial condition value
        this.deliveryCondition.instruction_id = "";
        this.deliveryCondition.owner_id = "";
        this.deliveryCondition.receptionDateFrom = "";
        this.deliveryCondition.receptionDateTo = "";
        this.deliveryCondition.deliveryStatusExistDeliveryError = true;
        this.deliveryCondition.deliveryStatusExistNoDelivery = true;
        this.deliveryCondition.deliveryStatusEnableDelivery = true;
        this.deliveryCondition.deliveryStatusDeliveryComplete = false;
        this.deliveryCondition.deliveryStatusStop = false;
        this.deliveryCondition.productKind = "";
        this.deliveryCondition.delivery_personnel = "";
        this.deliveryCondition.delivery_comment = "";
        this.messageList = [""];
        this.serchBtnCtl = false;
        return;
    }

    changeCondition() {
        console.log("DeliveryListComponent---------------changeCondition() ----------------------");
        this.serchBtnCtl = false;
        return;
    }

    selectRow(deliveryList: DeliveryList) {
        console.log("DeliveryListComponent---------------selectRow() ----------------------");
        this.selectedDeliveryList = deliveryList;
        this.deliveryDetailComponent.getDeliveryDetail(deliveryList);
        return;
    }

    // callBack from Detail Screen
    onDetailEvent(eventKind: string) {
        console.log("DeliveryListComponent--------onDetailEvent() ----------------------");
        console.log("DeliveryListComponent--------eventKind = " + eventKind);
        this.eventKind = eventKind;
        if(eventKind == CommonConst.CROSS_BUTTON) {
            this.hideDetailModal();
            return;
        }
        this.confirmModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_CONFIRM);                    
        this.showConfirmModal();
        return;
    }

    // callBack from Confirm Screen
    onConfirmModalEvent(btnKind: string) {
        console.log("DeliveryListComponent---------------onConfirmModalOkEvent() ----------------------");
        console.log("DeliveryListComponent--eventKind = " + btnKind);
        this.hideConfirmModal();
        if(btnKind == "Ok") {
            // get Delivery detail
            this.deliveryService.getDeliveryDetail(this.selectedDeliveryList).subscribe(
              data => {
                this.getDeliveryDetailResult = data; 
                if(this.getDeliveryDetailResult.result) {
                    console.log("DeliveryListComponent---------------getDeliveryDetail() ---success---------");
                    this.deliveryDetail = this.getDeliveryDetailResult.resultArray[0];
                    // status check
                    if(this.deliveryDetail.delivery_status != CommonConst.DELIVERY_STATUS_INSPECTABLE) {
                        this.showErrorModal();
                        this.errorModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_STATUS_NG);
                        return;                                     
                    }
                    // get Delivery detail(farms list)
                    this.inspectionCondition = new InspectionCondition();
                    this.inspectionCondition.instruction_id = this.deliveryDetail.instruction_id;
                    this.inspectionCondition.processKind = CommonConst.API_SEARCH_KIND_ALL_LIST;
                    this.inspectionService.getInspections(this.inspectionCondition).subscribe(
                        data => {
                            this.getInspectionsResult = data;
                            if(this.getInspectionsResult.result) {
                                console.log("DeliveryListComponent----getInspections() success-------");
                                this.inspectionLists = this.getInspectionsResult.resultArray;
                                // status check
                                console.log("DeliveryListComponent----getInspections() status check-");
                                for(let inspectionList of this.inspectionLists) {
                                    console.log(inspectionList.inspection_result);
                                    if(inspectionList.inspection_result != CommonConst.INSPECTION_RESULT_OK && inspectionList.inspection_result != CommonConst.INSPECTION_RESULT_NG) {
                                        this.showErrorModal();
                                        this.errorModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_STATUS_NG);
                                        return;                                     
                                    }
                                }
                                // execute delivery
                                this.showProgressModal();
                                this.loopStop = false;
                                this.loopNum = 0;
                                this.progressModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_PROGRESS, 0, this.inspectionLists.length, true, false);
                                this.progressObservable.subscribe(() => { });
                                return;
                            } else {
                                console.log("DeliveryListComponent----getInspections() error(data)-------");
                                console.log(this.getInspectionsResult.message);
                                this.showErrorModal();
                                this.errorModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_NG);                              
                                return;
                            }
                        },
                        error => {
                            console.log("DeliveryListComponent----getDeliveryDetail() error(error)-------");
                            console.log(error);
                            this.showErrorModal();
                            this.errorModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_NG);                              
                            return;
                        }
                    );//getInspections subscribe end 
                } else {
                  console.log("DeliveryListComponent---------------getDeliveryDetail() ---error(data)---------");
                  console.log(this.getDeliveryDetailResult.message);
                  this.showErrorModal();
                  this.errorModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_NG);
                  return;
                }    
              },
              error => {
                console.log("DeliveryListComponent---------------getDeliveryDetail() ---error(error)---------");
                console.log(this.getDeliveryDetailResult.message);
                console.log(error);
                this.showErrorModal();
                this.errorModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_NG);
                return;
              }
            );// getDeliveryDetail subscribe end
      
        } else { // cancel button 
            this.selectRow(this.selectedDeliveryList);
            this.showDetailModal();
            return;
        }
    }

    public progressObservable: Observable<string> = new Observable((observer => {
        if( this.loopStop) { 
            return;
        }
        if( this.loopNum < this.inspectionLists.length ) {
            let inspectionList: InspectionList = this.inspectionLists[this.loopNum];
            // execute delivery
            this.deliveryService.executeDelivery(inspectionList).subscribe(
                data => {
                    this.executeDeliveryResult = data;
                    if(this.executeDeliveryResult.result) {
                        console.log("DeliveryListComponent----executeDelivery() success-------");
                        if(inspectionList.inspection_status == CommonConst.INSPECTION_STATUS_DELIVERY_ERROR) {
                            let inspectionDetail = new InspectionDetail();
                            inspectionDetail.processKind = CommonConst.API_INSPECTION_UPDATE_KIND_UPDATE_STATUS_INSPECTED;
                            inspectionDetail.instruction_id = inspectionList.instruction_id;
                            inspectionDetail.field_id = inspectionList.field_id;
                            inspectionDetail.inspection_status = CommonConst.INSPECTION_STATUS_INSPECTED;
                            this.inspectionService.updateInspectionDetail(inspectionDetail).subscribe(
                                data => {
                                    this.getInspectionsResult = data;
                                    if(this.getInspectionsResult.result) {
                                        console.log("DeliveryListComponent----updateInspectionDetail() success-------");
                                        return;
                                    } else {
                                        console.log("DeliveryListComponent----updateInspectionDetail() error(data)-------");
                                        console.log(this.getInspectionsResult.message);
                                        this.hideProgressModal();
                                        this.errorModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_NG);                              
                                        this.showErrorModal();
                                        this.loopStop = true;
                                        return;
                                    }
                                },
                                error => {
                                    console.log("DeliveryListComponent----updateInspectionDetail() error(error)-------");
                                    this.hideProgressModal();
                                    this.errorModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_NG);                              
                                    this.showErrorModal();
                                    this.loopStop = true;
                                    return;
                                }            
                            );// end  updateInspectionDetail subscribe 

                        }
                        this.nextObservable();
                        return;
                    } else {
                        console.log("DeliveryListComponent----executeDelivery() error(data)-------");
                        this.deliveryErrorExist = true;
                        let inspectionDetail = new InspectionDetail();
                        inspectionDetail.processKind = CommonConst.API_INSPECTION_UPDATE_KIND_UPDATE_STATUS_ERROR;
                        inspectionDetail.instruction_id = inspectionList.instruction_id;
                        inspectionDetail.field_id = inspectionList.field_id;
                        inspectionDetail.inspection_status = CommonConst.INSPECTION_STATUS_DELIVERY_ERROR;
                        this.inspectionService.updateInspectionDetail(inspectionDetail).subscribe(
                            data => {
                                this.getInspectionsResult = data;
                                if(this.getInspectionsResult.result) {
                                    console.log("DeliveryListComponent----updateInspectionDetail() success-------");
                                    this.nextObservable();
                                    return;
                                } else {
                                    console.log("DeliveryListComponent----updateInspectionDetail() error(data)-------");
                                    console.log(this.getInspectionsResult.message);
                                    this.showErrorModal();
                                    this.errorModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_NG);                              
                                    return;
                                }
                            },
                            error => {
                                console.log("DeliveryListComponent----updateInspectionDetail() error(error)-------");
                                this.showErrorModal();
                                this.errorModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_NG);                              
                                return;
                            }            
                        );// end  updateInspectionDetail subscribe 
                    }
                },
                error => {
                    console.log("DeliveryListComponent----executeDelivery() error(error)-------");
                    this.deliveryErrorExist = true;
                    
                    let inspectionDetail = new InspectionDetail();
                    inspectionDetail.processKind = CommonConst.API_INSPECTION_UPDATE_KIND_UPDATE_STATUS_ERROR;
                    inspectionDetail.instruction_id = inspectionList.instruction_id;
                    inspectionDetail.field_id = inspectionList.field_id;
                    inspectionDetail.inspection_status = CommonConst.INSPECTION_STATUS_DELIVERY_ERROR;
                    this.inspectionService.updateInspectionDetail(inspectionDetail).subscribe(
                      data => {
                        this.getInspectionsResult = data;
                        if(this.getInspectionsResult.result) {
                          console.log("DeliveryListComponent----updateInspectionDetail() success-------");
                          this.nextObservable();
                          return;
                        } else {
                          console.log("DeliveryListComponent----updateInspectionDetail() error(data)-------");
                          console.log(this.getInspectionsResult.message);
                          this.showErrorModal();
                          this.errorModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_NG);                              
                          return;
                        }
                      },
                      error => {
                        console.log("DeliveryListComponent----updateInspectionDetail() error(error)-------");
                        this.showErrorModal();
                        this.errorModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_NG);                              
                        return;
                      }            
                    );// end  updateInspectionDetail subscribe       
                }// end error
            );// end executeDelivery subscribe
        }
    }));

    nextObservable() {

        this.loopNum++;
        if (this.loopNum == this.inspectionLists.length) {
            // update status
            this.deliveryDetail.processKind = CommonConst.API_DELIVERY_UPDATE_KIND_UPDATE_STATUS;
            this.deliveryDetail.delivery_personnel = this.username;
            if (this.deliveryErrorExist) {
                this.deliveryDetail.delivery_status = CommonConst.DELIVERY_STATUS_ERROR;
            } else {
                this.deliveryDetail.delivery_status = CommonConst.DELIVERY_STATUS_COMPLETED;
            }
            this.deliveryService.updateDeliveryDetail(this.deliveryDetail).subscribe(
                data => {
                    this.updateDeliveryDetailResult = data; 
                    if(this.updateDeliveryDetailResult.result) {
                        console.log("DeliveryListComponent---------------updateDeliveryDetail() ---success---------");
                        if (this.deliveryErrorExist) {
                            // delivery Exists Errors
                            this.progressModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_FARM_NG, this.loopNum, this.inspectionLists.length, false, true);
                            return;
                        } else {
                            // delivery All Success
                            this.progressModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_OK, this.loopNum, this.inspectionLists.length, false, false);
                            return;
                        }
                    } else {
                        console.log("DeliveryListComponent---------------updateDeliveryDetail() ---error(data)---------");
                        console.log(this.updateDeliveryDetailResult.message);
                        this.showErrorModal();
                        this.errorModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_NG);      
                    }
                },
                error => {
                    console.log("DeliveryListComponent---------------updateDeliveryDetail() ---error(error)---------");
                    console.log(this.updateDeliveryDetailResult.message);
                    this.showErrorModal();
                    this.errorModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_NG);
                }
            );// updateDeliveryDetail subscribe end
          
        } else {
            this.progressModalComponent.setScreenStatus(MessageConst.MODAL_DELIVERY_PROGRESS, this.loopNum, this.inspectionLists.length, true, false);
            // call next delivery execute
            this.progressObservable.subscribe(() => {});
        }
    }

    // callBack from Progress Screen
    onProgressModalEvent(progressEventKind: string) {
        console.log("DeliveryListComponent---------------onProgressModalEvent() ----------------------");
        this.hideProgressModal();
        // re-search
        this.getDeliveryLists();
        return;
    }
    showProgressModal() {
        console.log("DeliveryListComponent---------------showProgressModal() ----------------------");
        $('#progressModal').modal('show');
        return;
    }
    hideProgressModal() {
        console.log("DeliveryListComponent---------------hideProgressModal() ----------------------");
        $('#progressModal').modal('hide');
        return;
    }
    onSuccessModalEvent() {
        console.log("DeliveryListComponent------------onSuccessModalEvent() -------------");
        this.hideSuccessModal();
        // re-search
        this.getDeliveryLists();    
        return;
    }
    onErrorModalEvent() {
        console.log("DeliveryListComponent------------onErrorModalEvent() -------------");
        this.hideErrorModal();
        // re-search
        this.selectRow(this.selectedDeliveryList);
        this.showDetailModal();
        return;

    }
    showSuccessModal() {
        console.log("DeliveryListComponent------------showSuccessModal() -------------");
        $('#successModal').modal('show');
        return;
    }
    hideSuccessModal() {
        console.log("DeliveryListComponent------------hideSuccessModal() -------------");
        $('#successModal').modal('hide');
        return;
    }
    showErrorModal() {
        console.log("DeliveryListComponent------------showErrorModal() -------------");
        $('#errorModal').modal('show');
        return;
    }
    hideErrorModal() {
        console.log("DeliveryListComponent------------hideErrorModal() -------------");
        $('#errorModal').modal('hide');
        return;
    }
    showConfirmModal() {
        console.log("DeliveryListComponent------------showConfirmModal() -------------");
        $('#confirmModal').modal('show');
        return;
    }
    hideConfirmModal() {
        console.log("DeliveryListComponent------------hideConfirmModal() -------------");
        $('#confirmModal').modal('hide');
        return;
    }
    showDetailModal() {
        console.log("DeliveryListComponent------------showDetailModal() -------------");
        $('#myModal').modal('show');
        return;
    }
    hideDetailModal() {
        console.log("DeliveryListComponent------------hideDetailModal() -------------");
        $('#myModal').modal('hide');
        return;
    }
    showLoadingModal() {
        console.log("DeliveryListComponent------------showLoadingModal() -------------");
        $('#modalLoad').modal('show');
        return;
    }
    hideLoadingModal() {
        console.log("DeliveryListComponent------------hideLoadingModal() -------------");
        $('#modalLoad').modal('hide');
        return;
    }

}

export class InspectionList {

    // schema valiable
    instruction_id: string;
    field_id: string;
    owner_id: string;
    request_datetime: string;
    inspection_status: string;
    inspection_status_display: string;
    inspection_result: string;
    inspection_result_display: string;
    inspection_personnel: string;
    inspection_personnel_display: string;
    comment: string;
    comment_display: string;
    field_information: string;
    error_code: string;
    analysis_result_data: string;
    inspection_result_data: string;
    update_flag: string;
}
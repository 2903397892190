import { Injectable } from '@angular/core';
import { Headers, Http,  Jsonp, RequestOptionsArgs, RequestOptions, URLSearchParams} from '@angular/http';
import { Observable } from 'rxjs';
import "rxjs";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import { CommonConst } from '../constant/common-const';
import { DeliveryList } from '../model/delivery-list';
import { DeliveryDetail } from '../model/delivery-detail';
import { DeliveryCondition } from '../model/delivery-condition';
import { DeliveryDetailList } from '../model/delivery-detail-list';
import { DeliveryListComponent } from '../component/delivery-list/delivery-list.component';
import { ExecuteResult } from '../model/execute-result';
import { InspectionList } from '../model/inspection-list';

@Injectable({
    providedIn: 'root'
})
export class DeliveryService {
    executeResult: ExecuteResult;
    deliveryDetail: DeliveryDetail;
    private deliveryList: DeliveryList;
    private deliveryLists: DeliveryList[];
    private deliveryDetailLists: DeliveryDetailList[];
    jsonResult: any;

    constructor(private http: Http) {
        console.log("<<DeliveryService>>---------constructor() --------------");
    }

    getDeliverys(deliveryCondition: DeliveryCondition): Observable<any> {
        console.log("<<DeliveryService>>---------getDeliverys() --------------");

        let headers = new Headers({ 'x-api-key': CommonConst.X_API_KEY });
        let apiParams = new URLSearchParams();
        apiParams.append('processKind', CommonConst.API_SEARCH_KIND_LIST);
        apiParams.append('owner_id', deliveryCondition.owner_id);
        apiParams.append('instruction_id', deliveryCondition.instruction_id);
        apiParams.append('receptionDateFrom', deliveryCondition.receptionDateFrom);
        apiParams.append('receptionDateTo', deliveryCondition.receptionDateTo);
        apiParams.append('deliveryStatusExistDeliveryError', String(deliveryCondition.deliveryStatusExistDeliveryError));
        apiParams.append('deliveryStatusExistNoDelivery', String(deliveryCondition.deliveryStatusExistNoDelivery));
        apiParams.append('deliveryStatusEnableDelivery', String(deliveryCondition.deliveryStatusEnableDelivery));
        apiParams.append('deliveryStatusDeliveryComplete', String(deliveryCondition.deliveryStatusDeliveryComplete));
        apiParams.append('deliveryStatusStop', String(deliveryCondition.deliveryStatusStop));
        apiParams.append('productKind', deliveryCondition.productKind);
        apiParams.append('delivery_personnel', deliveryCondition.delivery_personnel);
        apiParams.append('delivery_comment', deliveryCondition.delivery_comment);
        apiParams.append('startLineNumber', String(deliveryCondition.startLineNumber));
        apiParams.append('endLineNumber', String(deliveryCondition.endLineNumber));

        let options = new RequestOptions({ headers: headers, params: apiParams });

        return this.http.get(CommonConst.API_URL_DELIVERIES, options
        ).map(
            response => {
                console.log("---------response--------");
                console.log(response);  
                this.jsonResult = JSON.parse(response.text());
        
                console.log("Code = " + this.jsonResult.Code);
                console.log("Description = " + this.jsonResult.Description);  
                if (this.jsonResult.Code != CommonConst.HTTP_STATUS_CODE_OK) {
                    this.executeResult = {
                        resultArray: null,
                        totalCount: null,
                        result: false,
                        message: "API Response  " + this.jsonResult.Code + ":" + this.jsonResult.Description
                    };  
                    return this.executeResult;      
                }

                let deliveryList: DeliveryList;
                this.deliveryLists = [deliveryList];
                for (let jsonDeliveryList of this.jsonResult.apiResultLists) {
                    let product_kind_display: string = CommonConst.PRODUCT_KIND_UNKNOWN;
                    for (let deliveryResult of CommonConst.PRODUCT_KIND_LIST) {
                        if (deliveryResult["key"] == jsonDeliveryList.type) {
                            product_kind_display = deliveryResult["value"];
                            break;
                        }
                    }

                    let delivery_status_display: string;
                    for (let deliveryResult of CommonConst.DELIVERY_STATUS_LIST) {
                        if (deliveryResult["key"] == jsonDeliveryList.delivery_status) {
                            delivery_status_display = deliveryResult["value"];
                            break;
                        }
                    }

                    let delivery_personnel_display: string;
                    if(jsonDeliveryList.delivery_assign_user == CommonConst.EMPTY_TEXT_DB) {
                        delivery_personnel_display = "-";
                    } else {
                        delivery_personnel_display = jsonDeliveryList.delivery_assign_user;
                    }
                    let comment_display: string;
                    if(jsonDeliveryList.delivery_comment == CommonConst.EMPTY_TEXT_DB) {
                        comment_display = "-";
                    } else {
                        comment_display = jsonDeliveryList.delivery_comment;
                    }

                    let rowClass = "";
                    if(jsonDeliveryList.delivery_status == CommonConst.DELIVERY_STATUS_NOT_INSPECTED || jsonDeliveryList.delivery_status == CommonConst.DELIVERY_STATUS_INSPECTABLE) {
                        let year   = parseInt(jsonDeliveryList.request_date.substr(0,4));
                        let month  = parseInt(jsonDeliveryList.request_date.substr(5,2));
                        month  = month -1;
                        let day    = parseInt(jsonDeliveryList.request_date.substr(8,2));
                        let hour   = parseInt(jsonDeliveryList.request_date.substr(11,2));
                        let minute = parseInt(jsonDeliveryList.request_date.substr(14,2));
                        let second = parseInt(jsonDeliveryList.request_date.substr(17,2));
                        let requestDate = new Date(year,month,day,hour,minute,second);
                        let nowDateTime = new Date();
                        let elapsedTime = nowDateTime.getTime() - requestDate.getTime();
                        let expireMilliSecond = CommonConst.HOUR_MILLISECOND * CommonConst.WARNING_HOUR_AFTER_RECEPTION;
                        if(expireMilliSecond < elapsedTime) {
                            rowClass = "redrow";
                        }
                    }
                    deliveryList = {
                        owner_id: jsonDeliveryList.owner_id,
                        instruction_id: jsonDeliveryList.instruction_id,
                        request_datetime: jsonDeliveryList.request_date,
                        product_kind: jsonDeliveryList.type,
                        product_kind_display: product_kind_display,
                        row_class: rowClass,

                        delivery_status: jsonDeliveryList.delivery_status,
                        delivery_status_display: delivery_status_display,

                        delivery_personnel: jsonDeliveryList.delivery_assign_user,
                        delivery_personnel_display: delivery_personnel_display,
                        comment: jsonDeliveryList.delivery_comment,
                        comment_display: comment_display
                    };
                    this.deliveryLists.push(deliveryList);
                }
                this.deliveryLists.shift();
        
                this.executeResult = {
                    resultArray: this.deliveryLists,
                    totalCount: null,
                    result: true,
                    message:"getDeliverys success"
                };  
                return this.executeResult;    
            }
        ).catch (
            error => {
                console.log("delivery error ");
                return Observable.throw(error.statusText);
            }
        );
    }

    getDeliverysTotalCount(deliveryCondition: DeliveryCondition): Observable<any> {
        console.log("<<DeliveryService>>---------getDeliverysTotalCount() --------------");
    
        let headers = new Headers({ 'x-api-key': CommonConst.X_API_KEY });
        let apiParams = new URLSearchParams();
        apiParams.append('processKind', CommonConst.API_SEARCH_KIND_TOTALCOUNT);
        apiParams.append('owner_id', deliveryCondition.owner_id);
        apiParams.append('instruction_id', deliveryCondition.instruction_id);
        apiParams.append('receptionDateFrom', deliveryCondition.receptionDateFrom);
        apiParams.append('receptionDateTo', deliveryCondition.receptionDateTo);
        apiParams.append('deliveryStatusExistDeliveryError', String(deliveryCondition.deliveryStatusExistDeliveryError));
        apiParams.append('deliveryStatusExistNoDelivery', String(deliveryCondition.deliveryStatusExistNoDelivery));
        apiParams.append('deliveryStatusEnableDelivery', String(deliveryCondition.deliveryStatusEnableDelivery));
        apiParams.append('deliveryStatusDeliveryComplete', String(deliveryCondition.deliveryStatusDeliveryComplete));
        apiParams.append('deliveryStatusStop', String(deliveryCondition.deliveryStatusStop));
        apiParams.append('productKind', deliveryCondition.productKind);
        apiParams.append('delivery_personnel', deliveryCondition.delivery_personnel);
        apiParams.append('delivery_comment', deliveryCondition.delivery_comment);
        apiParams.append('startLineNumber', String(deliveryCondition.startLineNumber));
        apiParams.append('endLineNumber', String(deliveryCondition.endLineNumber));

        let options = new RequestOptions({ headers: headers, params: apiParams });
        return this.http.get(CommonConst.API_URL_DELIVERIES, options
        ).map(
            response => {
                console.log("---------response--------");
                console.log(response);        
                this.jsonResult = JSON.parse(response.text());
                console.log("Code = " + this.jsonResult.Code);
                console.log("Description = " + this.jsonResult.Description);
        
                if (this.jsonResult.Code != CommonConst.HTTP_STATUS_CODE_OK) {
                    this.executeResult = {
                        resultArray: null,
                        totalCount: this.jsonResult.totalCount,
                        result: false,
                        message: "API Response  " + this.jsonResult.Code + ":" + this.jsonResult.Description
                    };  
                    return this.executeResult;      
                }
                this.executeResult = {
                    resultArray: null,
                    totalCount: this.jsonResult.totalCount,
                    result: true,
                    message:"getDelivereysTotalCount success"
                };  
                return this.executeResult;    
            }
        ).catch (
            error => {
                console.log("delivery list search erorr");
                return Observable.throw(error.statusText);
            }
        );
    }

    getPageDeliverys(deliveryCondition: DeliveryCondition): Observable<any> {
        console.log("<<DeliveryService>>---------getPageDeliverys() --------------");

        let headers = new Headers({ 'x-api-key': CommonConst.X_API_KEY });
        let apiParams = new URLSearchParams();
        apiParams.append('processKind', CommonConst.API_SEARCH_KIND_PAGE);
        apiParams.append('owner_id', deliveryCondition.owner_id);
        apiParams.append('instruction_id', deliveryCondition.instruction_id);
        apiParams.append('receptionDateFrom', deliveryCondition.receptionDateFrom);
        apiParams.append('receptionDateTo', deliveryCondition.receptionDateTo);
        apiParams.append('deliveryStatusExistDeliveryError', String(deliveryCondition.deliveryStatusExistDeliveryError));
        apiParams.append('deliveryStatusExistNoDelivery', String(deliveryCondition.deliveryStatusExistNoDelivery));
        apiParams.append('deliveryStatusEnableDelivery', String(deliveryCondition.deliveryStatusEnableDelivery));
        apiParams.append('deliveryStatusDeliveryComplete', String(deliveryCondition.deliveryStatusDeliveryComplete));
        apiParams.append('deliveryStatusStop', String(deliveryCondition.deliveryStatusStop));
        apiParams.append('productKind', deliveryCondition.productKind);
        apiParams.append('delivery_personnel', deliveryCondition.delivery_personnel);
        apiParams.append('delivery_comment', deliveryCondition.delivery_comment);
        apiParams.append('startLineNumber', String(deliveryCondition.startLineNumber));
        apiParams.append('endLineNumber', String(deliveryCondition.endLineNumber));
        apiParams.append('startLineKeyInstruction_id', deliveryCondition.start_instruction_id);

        let options = new RequestOptions({ headers: headers, params: apiParams });
        return this.http.get(CommonConst.API_URL_DELIVERIES, options
        ).map(
            response => {
                console.log("---------response--------");
                console.log(response);

                this.jsonResult = JSON.parse(response.text());

                console.log("Code = " + this.jsonResult.Code);
                console.log("Description = " + this.jsonResult.Description);        

                if (this.jsonResult.Code != CommonConst.HTTP_STATUS_CODE_OK) {
                    this.executeResult = {
                        totalCount: null,
                        resultArray: null,
                        result: false,
                        message: "API Response  " + this.jsonResult.Code + ":" + this.jsonResult.Description
                    };  
                    return this.executeResult;      
                }

                let deliveryList: DeliveryList;
                this.deliveryLists = [deliveryList];
                for (let jsonDeliveryList of this.jsonResult.apiResultLists) {
                    let product_kind_display: string = CommonConst.PRODUCT_KIND_UNKNOWN;
                    for (let deliveryResult of CommonConst.PRODUCT_KIND_LIST) {
                        if (deliveryResult["key"] == jsonDeliveryList.type) {
                            product_kind_display = deliveryResult["value"];
                            break;
                        }
                    }
                    let delivery_status_display: string;
                    for (let deliveryResult of CommonConst.DELIVERY_STATUS_LIST) {
                        if (deliveryResult["key"] == jsonDeliveryList.delivery_status) {
                            delivery_status_display = deliveryResult["value"];
                            break;
                        }
                    }
                    let delivery_personnel_display: string;
                    if(jsonDeliveryList.delivery_assign_user == CommonConst.EMPTY_TEXT_DB) {
                        delivery_personnel_display = "-";
                    } else {
                        delivery_personnel_display = jsonDeliveryList.delivery_assign_user;
                    }
                    let comment_display: string;
                    if(jsonDeliveryList.delivery_comment == CommonConst.EMPTY_TEXT_DB) {
                        comment_display = "-";
                    } else {
                        comment_display = jsonDeliveryList.delivery_comment;
                    }
                    let rowClass = "";
                    if(jsonDeliveryList.delivery_status == CommonConst.DELIVERY_STATUS_NOT_INSPECTED || jsonDeliveryList.delivery_status == CommonConst.DELIVERY_STATUS_INSPECTABLE) {
                        let year   = parseInt(jsonDeliveryList.request_date.substr(0,4));
                        let month  = parseInt(jsonDeliveryList.request_date.substr(5,2));
                        month  = month -1;
                        let day    = parseInt(jsonDeliveryList.request_date.substr(8,2));
                        let hour   = parseInt(jsonDeliveryList.request_date.substr(11,2));
                        let minute = parseInt(jsonDeliveryList.request_date.substr(14,2));
                        let second = parseInt(jsonDeliveryList.request_date.substr(17,2));
                        let requestDate = new Date(year,month,day,hour,minute,second);
                        let nowDateTime = new Date();
                        let elapsedTime = nowDateTime.getTime() - requestDate.getTime();
                        let expireMilliSecond = CommonConst.HOUR_MILLISECOND * CommonConst.WARNING_HOUR_AFTER_RECEPTION;
                        if(expireMilliSecond < elapsedTime) {
                            rowClass = "redrow";
                        }
                    }
                    deliveryList = {
                        owner_id: jsonDeliveryList.owner_id,
                        instruction_id: jsonDeliveryList.instruction_id,
                        request_datetime: jsonDeliveryList.request_date,
                        product_kind: jsonDeliveryList.type,
//                        row_class: jsonDeliveryList.row_class,//redrow
                        row_class: rowClass,

                        delivery_status: jsonDeliveryList.delivery_status,
                        delivery_status_display: delivery_status_display,
                        product_kind_display: product_kind_display,
                        delivery_personnel: jsonDeliveryList.delivery_assign_user,
                        delivery_personnel_display: delivery_personnel_display,
                        comment: jsonDeliveryList.delivery_comment,
                        comment_display: comment_display
                    };
                    this.deliveryLists.push(deliveryList);
                }
                this.deliveryLists.shift();

                this.executeResult = {
                    resultArray: this.deliveryLists,
                    totalCount: this.jsonResult.totalCount,
                    result: true,
                    message:"getPageDeliverys success"
                };  
                return this.executeResult;    
            }
        ).catch (
            error => {
                console.log("delivery list search paging error");
                return Observable.throw(error.statusText);
            }
        );
    }

    getDeliveryDetail(deliveryList: DeliveryList): Observable<any> {
        console.log("<<DeliveryService>>---------getDeliveryDetail() --------------");

        let headers = new Headers({ 'x-api-key': CommonConst.X_API_KEY });
        let apiParams = new URLSearchParams();
        apiParams.append('processKind', CommonConst.API_SEARCH_KIND_DETAIL);
        apiParams.append('instruction_id', deliveryList.instruction_id);

        let options = new RequestOptions({ headers: headers, params: apiParams });
        return this.http.get(CommonConst.API_URL_DELIVERIES, options
        ).map(
            response => {
                console.log("---------response--------");
                console.log(response);
                this.jsonResult = JSON.parse(response.text());
                console.log("Code = " + this.jsonResult.Code);
                console.log("Description = " + this.jsonResult.Description);
        
                if (this.jsonResult.Code != CommonConst.HTTP_STATUS_CODE_OK) {
                    this.executeResult = {
                        resultArray: null,
                        totalCount: null,
                        result: false,
                        message: "API Response  " + this.jsonResult.Code + ":" + this.jsonResult.Description
                    };  
                    return this.executeResult;      
                }

                let delivery_status_display: string;
                for (let deliveryResult of CommonConst.DELIVERY_STATUS_LIST) {
                    if (deliveryResult["key"] == this.jsonResult.apiResultLists[0].delivery_status) {
                        delivery_status_display = deliveryResult["value"];
                        break;
                    }
                }
                let delivery_personnel_display: string;
                if(this.jsonResult.apiResultLists[0].delivery_assign_user == CommonConst.EMPTY_TEXT_DB) {
                    delivery_personnel_display = "";
                } else {
                    delivery_personnel_display = this.jsonResult.apiResultLists[0].delivery_assign_user;
                }
                let comment_display: string;
                if(this.jsonResult.apiResultLists[0].delivery_comment == CommonConst.EMPTY_TEXT_DB) {
                    comment_display = "";
                } else {
                    comment_display = this.jsonResult.apiResultLists[0].delivery_comment;
                }
                let delivery_enable_display = "×";
                if (this.jsonResult.apiResultLists[0].delivery_status == CommonConst.DELIVERY_STATUS_INSPECTABLE) {
                    delivery_enable_display = "〇";
                }
                this.deliveryDetail = new DeliveryDetail();
                this.deliveryDetail.owner_id = this.jsonResult.apiResultLists[0].owner_id;
                this.deliveryDetail.instruction_id = this.jsonResult.apiResultLists[0].instruction_id;
                this.deliveryDetail.request_date = this.jsonResult.apiResultLists[0].request_date;
                this.deliveryDetail.delivery_status = this.jsonResult.apiResultLists[0].delivery_status;
                this.deliveryDetail.delivery_status_display = delivery_status_display;
                this.deliveryDetail.delivery_personnel = delivery_personnel_display;
                this.deliveryDetail.delivery_comment = comment_display;
                this.deliveryDetail.delivery_enable = delivery_enable_display;

                this.executeResult = {
                  resultArray: [this.deliveryDetail],
                  totalCount: null,
                  result: true,
                  message: "getDeliveryDetail success"
                };  
                return this.executeResult;    
            }
        ).catch (
            error => {
              console.log("delivery detail error");
              return Observable.throw(error.statusText);
            }
        );
    }

    updateDeliveryDetail(deliveryDetail: DeliveryDetail): Observable<any> {
        console.log("<<DeliveryService>>---------updateDeliveryDetail() --------------");
        let headers = new Headers({ 'x-api-key': CommonConst.X_API_KEY });        
        let options = new RequestOptions({ headers: headers});
        let body: any = {
            processKind: deliveryDetail.processKind,
            instruction_id: deliveryDetail.instruction_id,
            delivery_comment: deliveryDetail.delivery_comment,
            owner_id: deliveryDetail.owner_id,
            delivery_status: deliveryDetail.delivery_status,
            limit_date: "",
            type: "",
            delivery_date: "",
            delivery_assign_user: deliveryDetail.delivery_personnel,
            update_date: "",
            request_date: ""
        };
        return this.http.put(CommonConst.API_URL_DELIVERIES,
        body,
        options
        ).map(
            response => {
                console.log("---------response--------");
                console.log(response);

                this.jsonResult = JSON.parse(response.text());

                console.log("Code = " + this.jsonResult.Code);
                console.log("Description = " + this.jsonResult.Description);

                if (this.jsonResult.Code != CommonConst.HTTP_STATUS_CODE_OK) {
                    this.executeResult = {
                        resultArray: null,
                        totalCount: null,
                        result: false,
                        message: "API Response  " + this.jsonResult.Code + ":" + this.jsonResult.Description
                    };  
                    return this.executeResult;      
                }
                this.executeResult = {
                    resultArray: null,
                    totalCount: null,
                    result: true,
                    message:"updateDeliveryDetail success"
                };  
                return this.executeResult;    
            }
        ).catch (
            error => {
                console.log("delivery update error");
                return Observable.throw(error.statusText);
            }
        );
    }

    executeDelivery(inspectionList: InspectionList): Observable<any> {
        console.log("<<DeliveryService>>---------executeDelivery() --------------");
        let headers = new Headers({ 'x-api-key': CommonConst.X_API_KEY });        
        let options = new RequestOptions({ headers: headers});
        let body: any = {
          analysis_result_data: inspectionList.analysis_result_data,
          inspection_result_data: inspectionList.inspection_result_data,
          update_flag: inspectionList.update_flag
        };
        return this.http.post(CommonConst.API_URL_DELIVERIES,
        body,
        options
        ).map(
            response => {
                console.log(response);

                this.jsonResult = JSON.parse(response.text());

                console.log("statusText = " + this.jsonResult.statusText);
                console.log("statusCode = " + this.jsonResult.statusCode);

                if (this.jsonResult.statusCode != CommonConst.HTTP_STATUS_CODE_OK) {
                    this.executeResult = {
                        resultArray: null,
                        totalCount: null,
                        result: false,
                        message: "API Response  " + this.jsonResult.statusCode + ":" + this.jsonResult.statusText
                    };  
                    return this.executeResult;      
                }
                this.executeResult = {
                    resultArray: null,
                    totalCount: null,
                    result: true,
                    message:"executeDelivery success"
                };  
                return this.executeResult;    
            }
        ).catch (
            error => {
                console.log("delivery exec error");
                return Observable.throw(error.statusText);
            }
        );
    }
}

export class DeliveryCondition {

    // schema valiable
    instruction_id: string;
    start_instruction_id: string;
    owner_id: string;
    delivery_personnel: string;
    delivery_comment: string;

    // internal valiable
    page: number;
    startLineNumber: number;
    endLineNumber: number;
    receptionDateFrom: string;
    receptionDateTo: string;    
    deliveryStatusExistDeliveryError: boolean;
    deliveryStatusExistNoDelivery: boolean;
    deliveryStatusEnableDelivery: boolean;
    deliveryStatusDeliveryComplete: boolean;
    deliveryStatusStop: boolean;
    productKind: string;
    instructionIdKeyList: string[];

}
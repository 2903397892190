export class DeliveryList {

    // schema valiable
    instruction_id: string;
    owner_id: string;
    request_datetime: string;
    delivery_status: string;
    delivery_status_display: string;
    product_kind: string;
    product_kind_display: string;
    delivery_personnel: string;
    delivery_personnel_display: string;
    comment: string;
    comment_display: string;
    row_class: string;
}
export class CommonConst {

    public static readonly UI_SYSTEM_VERSION          = "Ver.0.9.9";
    public static readonly UI_SYSTEM_INTERNAL_VERSION = "Ver.0.9.9.5";

    public static readonly EMPTY_TEXT_DB             = "NULL";
    public static readonly CROSS_BUTTON              = "CROSS_BTN";
    public static readonly HTTP_STATUS_CODE_OK       = "200";

// production
//    public static readonly X_API_KEY                 = "as5No46VBw9g5Pa26Baw76jkS6o8QTfx8h3uk0Ca";
//    public static readonly API_URL_FIELDS            = "https://dj9oi6b7bb.execute-api.ap-northeast-1.amazonaws.com/v1/fields";
//    public static readonly API_URL_DELIVERIES        = "https://dj9oi6b7bb.execute-api.ap-northeast-1.amazonaws.com/v1/deliveries";

// staging
//public static readonly X_API_KEY                 = "r8d3Fgk30RjwSqYQ1YnI3NzRSf1kETl8btVJqFn1";
//public static readonly API_URL_FIELDS            = "https://9z8hkaxcr7.execute-api.ap-northeast-1.amazonaws.com/v3/fields";
//public static readonly API_URL_DELIVERIES        = "https://9z8hkaxcr7.execute-api.ap-northeast-1.amazonaws.com/v3/deliveries";

// staging secondary
public static readonly X_API_KEY                 = "r8d3Fgk30RjwSqYQ1YnI3NzRSf1kETl8btVJqFn1";
public static readonly API_URL_FIELDS            = "https://9z8hkaxcr7.execute-api.ap-northeast-1.amazonaws.com/v2/fields";
public static readonly API_URL_DELIVERIES        = "https://9z8hkaxcr7.execute-api.ap-northeast-1.amazonaws.com/v2/deliveries";

    public static readonly API_SEARCH_KIND_LIST       = "LIST";
    public static readonly API_SEARCH_KIND_ALL_LIST   = "ALL_LIST";
    public static readonly API_SEARCH_KIND_PAGE       = "PAGE";
    public static readonly API_SEARCH_KIND_DETAIL     = "DETAIL";
    public static readonly API_SEARCH_KIND_TOTALCOUNT = "TOTALCOUNT";

    public static readonly API_INSPECTION_UPDATE_KIND_UPDATE_COMMET           = "UPDATE_COMMET";
    public static readonly API_INSPECTION_UPDATE_KIND_UPDATE_STATUS           = "UPDATE_STATUS";
    public static readonly API_INSPECTION_UPDATE_KIND_UPDATE_STATUS_ERROR     = "UPDATE_STATUS_DELIVERY_ERROR";
    public static readonly API_INSPECTION_UPDATE_KIND_UPDATE_STATUS_INSPECTED = "UPDATE_STATUS_INSPECTED";
    public static readonly API_INSPECTION_UPDATE_KIND_UPDATE_INCLUDE_MODIFY   = "UPDATE_INCLUDE_MODIFY";

    public static readonly API_DELIVERY_UPDATE_KIND_UPDATE_COMMET           = "UPDATE_COMMET";
    public static readonly API_DELIVERY_UPDATE_KIND_UPDATE_STATUS           = "UPDATE_STATUS";

    // INDICATE_ID PROGRESS
    public static readonly DELIVERY_STATUS_RECEIVED      = "received";
    public static readonly DELIVERY_STATUS_ANALISYS      = "analysis";
    public static readonly DELIVERY_STATUS_ERROR         = "error";
    public static readonly DELIVERY_STATUS_NOT_INSPECTED = "not_inspected";
    public static readonly DELIVERY_STATUS_INSPECTABLE   = "deliverable";
    public static readonly DELIVERY_STATUS_CANCEL        = "cancel";
    public static readonly DELIVERY_STATUS_COMPLETED     = "completed";
    public static readonly DELIVERY_STATUS_LIST = [
                                                    {"key":"received","value":"受付状態"},
                                                    {"key":"analysis","value":"解析中"},
                                                    {"key":"error","value":"納品エラーあり"},
                                                    {"key":"not_inspected","value":"未検品あり"},
                                                    {"key":"deliverable","value":"納品可能"},
                                                    {"key":"cancel","value":"中止"},
                                                    {"key":"completed","value":"納品完了"}
                                                ];

    // FARM_ID PROGRESS
    public static readonly INSPECTION_STATUS_RECEIVED       = "received";
    public static readonly INSPECTION_STATUS_ANALYSIS       = "analysis";
    public static readonly INSPECTION_STATUS_ANALYSIS_ERROR = "analysis_error";
    public static readonly INSPECTION_STATUS_WAITING        = "waiting";
    public static readonly INSPECTION_STATUS_MODIFY         = "modify";
    public static readonly INSPECTION_STATUS_INSPECTED      = "inspected";
    public static readonly INSPECTION_STATUS_DELIVERY_ERROR = "delivery_error";
    public static readonly INSPECTION_STATUS_LIST = [
                                                        {"key":"received","value":"受付状態"},
                                                        {"key":"analysis","value":"解析中"},
                                                        {"key":"analysis_error","value":"解析エラー"},
                                                        {"key":"waiting","value":"検品待ち"},
                                                        {"key":"modify","value":"修正待ち"},
                                                        {"key":"inspected","value":"検品済み"},
                                                        {"key":"delivery_error","value":"納品エラー"}
                                                    ];
    // INSPECTION RESULT
    public static readonly INSPECTION_RESULT_NOT_INSPECTED = "not_inspected";
    public static readonly INSPECTION_RESULT_MODIFY        = "modify";
    public static readonly INSPECTION_RESULT_RESERVATION   = "reservation";
    public static readonly INSPECTION_RESULT_OK            = "ok";
    public static readonly INSPECTION_RESULT_NG            = "ng";
    public static readonly INSPECTION_RESULT_LIST = [
                                                        {"key":"not_inspected","value":"未検品"},
                                                        {"key":"modify","value":"要修正"},
                                                        {"key":"reservation","value":"保留"},
                                                        {"key":"ok","value":"OK"},
                                                        {"key":"ng","value":"NG"}
                                                    ];
    // UPDATE FLAG
    public static readonly INSPECTION_UPDATE_FLAG_ANALYSIS_RESULT = "0";
    public static readonly INSPECTION_UPDATE_FLAG_INSPECTION_RESULT = "1";

//    public static readonly S3_BUCKET_NAME       = "https://s3-ap-northeast-1.amazonaws.com/agriculture-resultata-management/";
    public static readonly S3_BUCKET_NAME       = "https://s3.amazonaws.com/agriculture-resultata-staiging/";
                                                   
    public static readonly IMAGE_FILE_VISIBLE   = "/bmp_rot_";
    public static readonly IMAGE_FILE_NDVI      = "/ndvi_rot_";
    public static readonly IMAGE_FILE_VCR       = "/vcr_rot_";
    public static readonly IMAGE_FILE_NDVIX     = "/nxv_rot_";
    public static readonly IMAGE_FILE_EXTENTION = ".png";

    // PRODUCT_KIND
    public static readonly PRODUCT_KIND_LIST = [
        {"key":"RCGD","value":""},
        {"key":"RCMD","value":"中干し"},
        {"key":"RCRP","value":"刈取り"},
        {"key":"RCOT","value":"水稲他"},
        {"key":"WHGD","value":"小麦0"},
        {"key":"WHMD","value":"小麦1"},
        {"key":"WHRP","value":"小麦2"},
        {"key":"WHOT","value":"小麦他"},
        {"key":"OTGD","value":"他0"},
        {"key":"OTMD","value":"他1"},
        {"key":"OTRP","value":"他2"},
        {"key":"OTOT","value":"他9"},
        {"key":"","value":"-"}
    ];
    public static readonly PRODUCT_KIND_UNKNOWN = "不明";
    public static readonly HOUR_MILLISECOND = 3600000;
    public static readonly WARNING_HOUR_AFTER_RECEPTION = 36;
    public static readonly OK_BTN_CTL_AVERAGE_THRESHOLD_1 = "0";
    public static readonly OK_BTN_CTL_AVERAGE_THRESHOLD_2 = "-1";

}

import { Injectable } from '@angular/core';
import { Headers, Http,  Jsonp, RequestOptionsArgs, RequestOptions, URLSearchParams} from '@angular/http';
import { Observable } from 'rxjs';
import "rxjs";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import { CommonConst } from '../constant/common-const';
import { InspectionList } from '../model/inspection-list';
import { InspectionDetail } from '../model/inspection-detail';
import { InspectionCondition } from '../model/inspection-condition';
import { InspectionListComponent } from '../component/inspection-list/inspection-list.component';
import { ExecuteResult } from '../model/execute-result';

@Injectable({
    providedIn: 'root'
})
export class InspectionService {
    executeResult: ExecuteResult;
    private inspectionLists:InspectionList[];
    inspectionDetail: InspectionDetail;
    jsonResult: any;

    test: string[];
    test2:string;

    
    constructor(private http: Http) {
        console.log("<<InspectionService>>---------constructor() --------------");
    }

    getInspections(inspectionCondition:InspectionCondition): Observable<any> {
        console.log("<<InspectionService>>---------getInspections() --------------");

        let headers = new Headers({ 'x-api-key': CommonConst.X_API_KEY });
        let apiParams = new URLSearchParams();
        apiParams.append('processKind', inspectionCondition.processKind);
        apiParams.append('owner_id', inspectionCondition.owner_id);
        apiParams.append('instruction_id', inspectionCondition.instruction_id);
        apiParams.append('receptionDateFrom', inspectionCondition.receptionDateFrom);
        apiParams.append('receptionDateTo', inspectionCondition.receptionDateTo);
        apiParams.append('inspectionStatusAnalysis', String(inspectionCondition.inspectionStatusAnalysis));
        apiParams.append('inspectionStatusAnalysisError', String(inspectionCondition.inspectionStatusAnalysisError));
        apiParams.append('inspectionStatusInspectionWait', String(inspectionCondition.inspectionStatusInspectionWait));
        apiParams.append('inspectionStatusModifyImageWait', String(inspectionCondition.inspectionStatusModifyImageWait));
        apiParams.append('inspectionStatusInspectionComplete', String(inspectionCondition.inspectionStatusInspectionComplete));
        apiParams.append('inspectionStatusStop', String(inspectionCondition.inspectionStatusStop));
        apiParams.append('inspectionStatusDeliveryError', String(inspectionCondition.inspectionStatusDeliveryError));
        apiParams.append('inspectionResultNotInspection', String(inspectionCondition.inspectionResultNotInspection));
        apiParams.append('inspectionResultPending', String(inspectionCondition.inspectionResultPending));
        apiParams.append('inspectionResultNecessaryModify', String(inspectionCondition.inspectionResultNecessaryModify));
        apiParams.append('inspectionResultOk', String(inspectionCondition.inspectionResultOk));
        apiParams.append('inspectionResultNg', String(inspectionCondition.inspectionResultNg));
        apiParams.append('inspection_personnel', inspectionCondition.inspection_personnel);
        apiParams.append('comment', inspectionCondition.comment);
        apiParams.append('startLineNumber', String(inspectionCondition.startLineNumber));
        apiParams.append('endLineNumber', String(inspectionCondition.endLineNumber));
        
        let options = new RequestOptions({ headers: headers, params: apiParams });

        return this.http.get(CommonConst.API_URL_FIELDS, options
            ).map(
                response => {
                  console.log("---------response--------");
                  console.log(response);
    
                  this.jsonResult = JSON.parse(response.text());
    
                  console.log("Code = " + this.jsonResult.Code);
                  console.log("Description = " + this.jsonResult.Description);        
    
                  if (this.jsonResult.Code != CommonConst.HTTP_STATUS_CODE_OK) {
                      this.executeResult = {
                          totalCount: null,
                          resultArray: null,
                          result: false,
                          message: "API Response  " + this.jsonResult.Code + ":" + this.jsonResult.Description
                      };  
                      return this.executeResult;      
                  }
                  let inspectionList: InspectionList;
                  this.inspectionLists = [inspectionList];
                  for (let jsonInspectionList of this.jsonResult.apiResultLists) {
                      let comment_display: string;
                      if(jsonInspectionList.comment == CommonConst.EMPTY_TEXT_DB) {
                          comment_display = "-";
                      } else {
                          comment_display = jsonInspectionList.comment;
                      }
                      let inspection_personnel_display: string;
                      if(jsonInspectionList.inspection_assign_user == CommonConst.EMPTY_TEXT_DB) {
                          inspection_personnel_display = "-";
                      } else {
                          inspection_personnel_display = jsonInspectionList.inspection_assign_user;
                      }
                      let inspection_result_display: string;
                      for (let inspectionResult of CommonConst.INSPECTION_RESULT_LIST) {
                        if (inspectionResult["key"] == jsonInspectionList.inspection_result) {
                          inspection_result_display = inspectionResult["value"];
                          break;
                        }
                      } 
                      let inspection_status_display: string;
                      for (let inspectionResult of CommonConst.INSPECTION_STATUS_LIST) {
                          if (inspectionResult["key"] == jsonInspectionList.inspection_status) {
                              inspection_status_display = inspectionResult["value"];
                              break;
                          }
                      } 
                      inspectionList = {
                          owner_id: jsonInspectionList.owner_id,
                          instruction_id: jsonInspectionList.instruction_id,
                          field_id: jsonInspectionList.field_id,
                          request_datetime: jsonInspectionList.request_date,
                          inspection_status: jsonInspectionList.inspection_status,
                          inspection_status_display: inspection_status_display,
                          inspection_result: jsonInspectionList.inspection_result,
                          inspection_result_display: inspection_result_display,            
                          inspection_personnel: jsonInspectionList.inspection_assign_user,
                          inspection_personnel_display: inspection_personnel_display,
                          comment: jsonInspectionList.comment,
                          comment_display: comment_display,
                          field_information: jsonInspectionList.field_information,
                          error_code: jsonInspectionList.error_code,
                          analysis_result_data: jsonInspectionList.analysis_result_data,
                          inspection_result_data: jsonInspectionList.inspection_result_data,
                          update_flag: jsonInspectionList.update_flag
                      };
                      this.inspectionLists.push(inspectionList);
                  }
                  this.inspectionLists.shift();
    
                  this.executeResult = {
                      resultArray: this.inspectionLists,
                      totalCount: this.jsonResult.totalCount,
                      result: true,
                      message:"getInspections success"
                  };  
                  return this.executeResult;    
                }
            ).catch (
                error => {
                    console.log("inspection list search error");
                    return Observable.throw(error.statusText);
                }
            );
    
    }

    getInspectionsTotalCount(inspectionCondition:InspectionCondition): Observable<any> {
      console.log("<<InspectionService>>---------getInspectionsTotalCount() --------------");

      let headers = new Headers({ 'x-api-key': CommonConst.X_API_KEY });
      let apiParams = new URLSearchParams();
      apiParams.append('processKind', CommonConst.API_SEARCH_KIND_TOTALCOUNT);
      apiParams.append('owner_id', inspectionCondition.owner_id);
      apiParams.append('instruction_id', inspectionCondition.instruction_id);
      apiParams.append('receptionDateFrom', inspectionCondition.receptionDateFrom);
      apiParams.append('receptionDateTo', inspectionCondition.receptionDateTo);
      apiParams.append('inspectionStatusAnalysis', String(inspectionCondition.inspectionStatusAnalysis));
      apiParams.append('inspectionStatusAnalysisError', String(inspectionCondition.inspectionStatusAnalysisError));
      apiParams.append('inspectionStatusInspectionWait', String(inspectionCondition.inspectionStatusInspectionWait));
      apiParams.append('inspectionStatusModifyImageWait', String(inspectionCondition.inspectionStatusModifyImageWait));
      apiParams.append('inspectionStatusInspectionComplete', String(inspectionCondition.inspectionStatusInspectionComplete));
      apiParams.append('inspectionStatusStop', String(inspectionCondition.inspectionStatusStop));
      apiParams.append('inspectionStatusDeliveryError', String(inspectionCondition.inspectionStatusDeliveryError));
      apiParams.append('inspectionResultNotInspection', String(inspectionCondition.inspectionResultNotInspection));
      apiParams.append('inspectionResultPending', String(inspectionCondition.inspectionResultPending));
      apiParams.append('inspectionResultNecessaryModify', String(inspectionCondition.inspectionResultNecessaryModify));
      apiParams.append('inspectionResultOk', String(inspectionCondition.inspectionResultOk));
      apiParams.append('inspectionResultNg', String(inspectionCondition.inspectionResultNg));
      apiParams.append('inspection_personnel', inspectionCondition.inspection_personnel);
      apiParams.append('comment', inspectionCondition.comment);
      apiParams.append('startLineNumber', String(inspectionCondition.startLineNumber));
      apiParams.append('endLineNumber', String(inspectionCondition.endLineNumber));
      
      let options = new RequestOptions({ headers: headers, params: apiParams });

      return this.http.get(CommonConst.API_URL_FIELDS, options
        ).map(
            response => {
                console.log("---------response--------");
                console.log(response);
        
                this.jsonResult = JSON.parse(response.text());
        
                console.log("Code = " + this.jsonResult.Code);
                console.log("Description = " + this.jsonResult.Description);
        
                if (this.jsonResult.Code != CommonConst.HTTP_STATUS_CODE_OK) {
                    this.executeResult = {
                        resultArray: null,
                        totalCount: null,
                        result: false,
                        message: "API Response  " + this.jsonResult.Code + ":" + this.jsonResult.Description
                    };  
                    return this.executeResult;      
                }
                this.executeResult = {
                    resultArray: null,
                    totalCount: this.jsonResult.totalCount,
                    result: true,
                    message:"getInspectionsTotalCount success"
                };  
                return this.executeResult;    
            }
        ).catch (
            error => {
                console.log("inspection list search error ");
                return Observable.throw(error.statusText);
            }
        );
    }

    getPageInspections(inspectionCondition:InspectionCondition): Observable<any> {
        console.log("<<InspectionService>>---------getPageInspections() --------------");

        let headers = new Headers({ 'x-api-key': CommonConst.X_API_KEY });
        let apiParams = new URLSearchParams();
        apiParams.append('processKind', CommonConst.API_SEARCH_KIND_PAGE);
        apiParams.append('owner_id', inspectionCondition.owner_id);
        apiParams.append('instruction_id', inspectionCondition.instruction_id);
        apiParams.append('receptionDateFrom', inspectionCondition.receptionDateFrom);
        apiParams.append('receptionDateTo', inspectionCondition.receptionDateTo);
        apiParams.append('inspectionStatusAnalysis', String(inspectionCondition.inspectionStatusAnalysis));
        apiParams.append('inspectionStatusAnalysisError', String(inspectionCondition.inspectionStatusAnalysisError));
        apiParams.append('inspectionStatusInspectionWait', String(inspectionCondition.inspectionStatusInspectionWait));
        apiParams.append('inspectionStatusModifyImageWait', String(inspectionCondition.inspectionStatusModifyImageWait));
        apiParams.append('inspectionStatusInspectionComplete', String(inspectionCondition.inspectionStatusInspectionComplete));
        apiParams.append('inspectionStatusStop', String(inspectionCondition.inspectionStatusStop));
        apiParams.append('inspectionStatusDeliveryError', String(inspectionCondition.inspectionStatusDeliveryError));
        apiParams.append('inspectionResultNotInspection', String(inspectionCondition.inspectionResultNotInspection));
        apiParams.append('inspectionResultPending', String(inspectionCondition.inspectionResultPending));
        apiParams.append('inspectionResultNecessaryModify', String(inspectionCondition.inspectionResultNecessaryModify));
        apiParams.append('inspectionResultOk', String(inspectionCondition.inspectionResultOk));
        apiParams.append('inspectionResultNg', String(inspectionCondition.inspectionResultNg));
        apiParams.append('inspection_personnel', inspectionCondition.inspection_personnel);
        apiParams.append('comment', inspectionCondition.comment);
        apiParams.append('startLineNumber', String(inspectionCondition.startLineNumber));
        apiParams.append('endLineNumber', String(inspectionCondition.endLineNumber));
        apiParams.append('startLineKeyInstruction_id', String(inspectionCondition.start_instruction_id));
        apiParams.append('startLineKeyField_id', String(inspectionCondition.start_field_id));

        let options = new RequestOptions({ headers: headers, params: apiParams });

        return this.http.get(CommonConst.API_URL_FIELDS, options
        ).map(
            response => {
              console.log("---------response--------");
              console.log(response);
      
              this.jsonResult = JSON.parse(response.text());
      
              console.log("Code = " + this.jsonResult.Code);
              console.log("Description = " + this.jsonResult.Description);        
      
              if (this.jsonResult.Code != CommonConst.HTTP_STATUS_CODE_OK) {
                  this.executeResult = {
                      totalCount: null,
                      resultArray: null,
                      result: false,
                      message: "API Response  " + this.jsonResult.Code + ":" + this.jsonResult.Description
                  };  
                  return this.executeResult;      
              }
              let inspectionList: InspectionList;
              this.inspectionLists = [inspectionList];
              for (let jsonInspectionList of this.jsonResult.apiResultLists) {
                  let comment_display: string;
                  if(jsonInspectionList.comment == CommonConst.EMPTY_TEXT_DB) {
                      comment_display = "-";
                  } else {
                      comment_display = jsonInspectionList.comment;
                  }
                  let inspection_personnel_display: string;
                  if(jsonInspectionList.inspection_assign_user == CommonConst.EMPTY_TEXT_DB) {
                      inspection_personnel_display = "-";
                  } else {
                      inspection_personnel_display = jsonInspectionList.inspection_assign_user;
                  }
                  let inspection_result_display: string;
                  for (let inspectionResult of CommonConst.INSPECTION_RESULT_LIST) {
                      if (inspectionResult["key"] == jsonInspectionList.inspection_result) {
                          inspection_result_display = inspectionResult["value"];
                          break;
                      }
                  } 
                  let inspection_status_display: string;
                  for (let inspectionResult of CommonConst.INSPECTION_STATUS_LIST) {
                      if (inspectionResult["key"] == jsonInspectionList.inspection_status) {
                          inspection_status_display = inspectionResult["value"];
                          break;
                      }
                  } 
                  inspectionList = {
                      owner_id: jsonInspectionList.owner_id,
                      instruction_id: jsonInspectionList.instruction_id,
                      field_id: jsonInspectionList.field_id,
                      request_datetime: jsonInspectionList.request_date,
                      inspection_status: jsonInspectionList.inspection_status,
                      inspection_status_display: inspection_status_display,
                      inspection_result: jsonInspectionList.inspection_result,
                      inspection_result_display: inspection_result_display,            
                      inspection_personnel: jsonInspectionList.inspection_assign_user,
                      inspection_personnel_display: inspection_personnel_display,
                      comment: jsonInspectionList.comment,
                      comment_display: comment_display,
                      field_information: jsonInspectionList.field_information,
                      error_code: jsonInspectionList.error_code,
                      analysis_result_data: jsonInspectionList.analysis_result_data,
                      inspection_result_data: jsonInspectionList.inspection_result_data,
                      update_flag: jsonInspectionList.update_flag
                  };
                  this.inspectionLists.push(inspectionList);
              }
              this.inspectionLists.shift();
      
              this.executeResult = {
                  resultArray: this.inspectionLists,
                  totalCount: this.jsonResult.totalCount,
                  result: true,
                  message:"getPageInspections success"
              };  
              return this.executeResult;    
            }
        ).catch (
            error => {
                console.log("inspection list search paging error");
                return Observable.throw(error.statusText);
            }
        );
    }
    
    getInspectionDetail(inspectionList: InspectionList): Observable<any> {
        console.log("<<InspectionService>>---------getInspectionDetail() --------------");
        let headers = new Headers({ 'x-api-key': CommonConst.X_API_KEY });
        let apiParams = new URLSearchParams();
        apiParams.append('processKind', CommonConst.API_SEARCH_KIND_DETAIL);
        apiParams.append('owner_id', inspectionList.owner_id);
        apiParams.append('instruction_id', inspectionList.instruction_id);
        apiParams.append('field_id', inspectionList.field_id);
        let options = new RequestOptions({ headers: headers, params: apiParams });

        return this.http.get(CommonConst.API_URL_FIELDS, options
        ).map(
            response => {
                console.log("---------response--------");
                console.log(response);
                this.jsonResult = JSON.parse(response.text());
                console.log("Code = " + this.jsonResult.Code);
                console.log("Description = " + this.jsonResult.Description);
      
                if (this.jsonResult.Code != CommonConst.HTTP_STATUS_CODE_OK) {
                    this.executeResult = {
                        totalCount: null,
                        resultArray: null,
                        result: false,
                        message: "API Response  " + this.jsonResult.Code + ":" + this.jsonResult.Description
                    };  
                    return this.executeResult;      
                }
                this.inspectionDetail = new InspectionDetail();

                this.inspectionDetail.owner_id = this.jsonResult.apiResultLists[0].owner_id;
                this.inspectionDetail.instruction_id = this.jsonResult.apiResultLists[0].instruction_id;
                this.inspectionDetail.field_id = this.jsonResult.apiResultLists[0].field_id;
                this.inspectionDetail.request_date = this.jsonResult.apiResultLists[0].request_date;

                let inspection_status_display: string;
                for (let inspectionResult of CommonConst.INSPECTION_STATUS_LIST) {
                    if (inspectionResult["key"] == this.jsonResult.apiResultLists[0].inspection_status) {
                        inspection_status_display = inspectionResult["value"];
                        break;
                    }
                } 
                this.inspectionDetail.inspection_status = this.jsonResult.apiResultLists[0].inspection_status;
                this.inspectionDetail.inspection_status_display = inspection_status_display;

                let inspection_result_display: string;
                for (let inspectionResult of CommonConst.INSPECTION_RESULT_LIST) {
                    if (inspectionResult["key"] == this.jsonResult.apiResultLists[0].inspection_result) {
                        inspection_result_display = inspectionResult["value"];
                        break;
                    }
                } 
                this.inspectionDetail.inspection_result = this.jsonResult.apiResultLists[0].inspection_result;
                this.inspectionDetail.inspection_result_display = inspection_result_display;

                let inspection_personnel_display: string;
                if(this.jsonResult.apiResultLists[0].inspection_assign_user == CommonConst.EMPTY_TEXT_DB) {
                    inspection_personnel_display = "";
                } else {
                    inspection_personnel_display = this.jsonResult.apiResultLists[0].inspection_assign_user;
                }
                this.inspectionDetail.inspection_personnel = this.jsonResult.apiResultLists[0].inspection_assign_user;
                this.inspectionDetail.inspection_personnel_display = inspection_personnel_display;

                let comment_display: string;
                if(this.jsonResult.apiResultLists[0].comment == CommonConst.EMPTY_TEXT_DB) {
                  comment_display = "";
                } else {
                    comment_display = this.jsonResult.apiResultLists[0].comment;
                }
                this.inspectionDetail.comment = this.jsonResult.apiResultLists[0].comment;
                this.inspectionDetail.comment_display = comment_display;

                let error_code_display: string;
                if(this.jsonResult.apiResultLists[0].error_code == CommonConst.EMPTY_TEXT_DB) {
                    error_code_display = "";
                } else {
                    error_code_display = this.jsonResult.apiResultLists[0].error_code;
                }
                this.inspectionDetail.fe_infomation = this.jsonResult.apiResultLists[0].error_code;
                this.inspectionDetail.fe_infomation_display = error_code_display;
                console.log("test update_flag = " + this.jsonResult.apiResultLists[0].update_flag);

                let detailResult;
                let isDataExist = false;
                if (this.jsonResult.apiResultLists[0].update_flag == CommonConst.INSPECTION_UPDATE_FLAG_ANALYSIS_RESULT) {
                    if (this.jsonResult.apiResultLists[0].analysis_result_data != CommonConst.EMPTY_TEXT_DB) {
                        detailResult = JSON.parse(this.jsonResult.apiResultLists[0].analysis_result_data);
                        isDataExist = true;
                    }
                } else if (this.jsonResult.apiResultLists[0].update_flag == CommonConst.INSPECTION_UPDATE_FLAG_INSPECTION_RESULT) {
                    if (this.jsonResult.apiResultLists[0].inspection_result_data != CommonConst.EMPTY_TEXT_DB) {
                        detailResult = JSON.parse(this.jsonResult.apiResultLists[0].inspection_result_data);
                        isDataExist = true;
                    }
                }

                if (isDataExist) {
                    let ndvi_average = parseFloat(detailResult["analysis-result"][0].average);
                    ndvi_average = Math.round(ndvi_average * 100) / 100;
                    let ndvi_variation = parseFloat(detailResult["analysis-result"][0].variation);
                    ndvi_variation = Math.round(ndvi_variation * 10) / 10;
                    let ndvi_cover_rate = parseFloat(detailResult["analysis-result"][0].rate);
                    ndvi_cover_rate = Math.round(ndvi_cover_rate * 1000) / 10;

                    this.inspectionDetail.ndvi_average = String(ndvi_average);
                    this.inspectionDetail.ndvi_variation = String(ndvi_variation) + "%";
                    this.inspectionDetail.ndvi_cover_rate = String(ndvi_cover_rate) + "%";

                    let skin_grafting_rate_average = parseFloat(detailResult["analysis-result"][1].average);
                    skin_grafting_rate_average = Math.round(skin_grafting_rate_average * 100) / 100;
                    let skin_grafting_rate_variation = parseFloat(detailResult["analysis-result"][1].variation);
                    skin_grafting_rate_variation = Math.round(skin_grafting_rate_variation * 10) / 10;
                    let skin_grafting_rate_cover_rate = parseFloat(detailResult["analysis-result"][1].rate);
                    skin_grafting_rate_cover_rate = Math.round(skin_grafting_rate_cover_rate * 1000) / 10;

                    this.inspectionDetail.skin_grafting_rate_average = String(skin_grafting_rate_average);
                    this.inspectionDetail.skin_grafting_rate_variation = String(skin_grafting_rate_variation) + "%";
                    this.inspectionDetail.skin_grafting_rate_cover_rate = String(skin_grafting_rate_cover_rate) + "%";
          
                    let ndvix_average = parseFloat(detailResult["analysis-result"][2].average);
                    ndvix_average = Math.round(ndvix_average * 100) / 100;
                    let ndvix_variation = parseFloat(detailResult["analysis-result"][2].variation);
                    ndvix_variation = Math.round(ndvix_variation * 10) / 10;
                    let ndvix_cover_rate = parseFloat(detailResult["analysis-result"][2].rate);
                    ndvix_cover_rate = Math.round(ndvix_cover_rate * 1000) / 10;

                    this.inspectionDetail.ndvix_average = String(ndvix_average);
                    this.inspectionDetail.ndvix_variation = String(ndvix_variation) + "%";
                    this.inspectionDetail.ndvix_cover_rate = String(ndvix_cover_rate) + "%";
                }

                this.inspectionDetail.visual_path             = CommonConst.S3_BUCKET_NAME + this.jsonResult.apiResultLists[0].instruction_id + CommonConst.IMAGE_FILE_VISIBLE + this.jsonResult.apiResultLists[0].field_id + CommonConst.IMAGE_FILE_EXTENTION;
                this.inspectionDetail.ndvi_path               = CommonConst.S3_BUCKET_NAME + this.jsonResult.apiResultLists[0].instruction_id + CommonConst.IMAGE_FILE_NDVI    + this.jsonResult.apiResultLists[0].field_id + CommonConst.IMAGE_FILE_EXTENTION;
                this.inspectionDetail.skin_grafting_rate_path = CommonConst.S3_BUCKET_NAME + this.jsonResult.apiResultLists[0].instruction_id + CommonConst.IMAGE_FILE_VCR     + this.jsonResult.apiResultLists[0].field_id + CommonConst.IMAGE_FILE_EXTENTION;
                this.inspectionDetail.ndvix_path              = CommonConst.S3_BUCKET_NAME + this.jsonResult.apiResultLists[0].instruction_id + CommonConst.IMAGE_FILE_NDVIX   + this.jsonResult.apiResultLists[0].field_id + CommonConst.IMAGE_FILE_EXTENTION;
                this.inspectionDetail.update_flag = this.jsonResult.apiResultLists[0].update_flag;
                this.inspectionDetail.analysis_result_data = this.jsonResult.apiResultLists[0].analysis_result_data;
                this.inspectionDetail.inspection_result_data = this.jsonResult.apiResultLists[0].inspection_result_data;
                this.inspectionDetail.error_code = this.jsonResult.apiResultLists[0].error_code;

                this.executeResult = {
                    resultArray: [this.inspectionDetail],
                    totalCount: null,
                    result: true,
                    message: "getInspectionDetail success"
                };  
                return this.executeResult;    
            }
        ).catch (
            error => {
                console.log("inspection detail search error");
                return Observable.throw(error.statusText);
            }
        );
    }

    updateInspectionDetail(inspectionDetail: InspectionDetail): Observable<any> {
        console.log("<<InspectionService>>---------updateInspectionDetail() --------------");

        // update Json attr
        let detailResult;
        let isDataExist = false;
        console.log("update_flag = " + inspectionDetail.update_flag);
        if (inspectionDetail.update_flag == CommonConst.INSPECTION_UPDATE_FLAG_ANALYSIS_RESULT) {
            console.log("update_flag = 0");
            if (inspectionDetail.analysis_result_data != CommonConst.EMPTY_TEXT_DB) {
                console.log("0 exists");
                detailResult = JSON.parse(inspectionDetail.analysis_result_data);
                isDataExist = true;
            }

        } else if (inspectionDetail.update_flag == CommonConst.INSPECTION_UPDATE_FLAG_INSPECTION_RESULT) {
            console.log("update_flag = 1");
            if (inspectionDetail.inspection_result_data != CommonConst.EMPTY_TEXT_DB) {
                console.log("1 exists");
                detailResult = JSON.parse(inspectionDetail.inspection_result_data);
                isDataExist = true;
            }
        }
        if(isDataExist) {
            if(inspectionDetail.inspection_result == CommonConst.INSPECTION_RESULT_OK) {
                console.log("OK Button");
                detailResult["analysis-result"][0]["result-flg"] = "1";
                detailResult["analysis-result"][1]["result-flg"] = "1";
                detailResult["analysis-result"][2]["result-flg"] = "1";
                detailResult["analysis-result"][0]["analysis-error-code"] = "0";
                detailResult["analysis-result"][1]["analysis-error-code"] = "0";
                detailResult["analysis-result"][2]["analysis-error-code"] = "0";

            } else if (inspectionDetail.inspection_result == CommonConst.INSPECTION_RESULT_NG) {
                detailResult["analysis-result"][0]["result-flg"] = "0";
                detailResult["analysis-result"][1]["result-flg"] = "0";
                detailResult["analysis-result"][2]["result-flg"] = "0";
                if(inspectionDetail.error_code == CommonConst.EMPTY_TEXT_DB) {
                    detailResult["analysis-result"][0]["analysis-error-code"] = "0";
                    detailResult["analysis-result"][1]["analysis-error-code"] = "0";
                    detailResult["analysis-result"][2]["analysis-error-code"] = "0";
                } else {
                    detailResult["analysis-result"][0]["analysis-error-code"] = inspectionDetail.error_code;
                    detailResult["analysis-result"][1]["analysis-error-code"] = inspectionDetail.error_code;
                    detailResult["analysis-result"][2]["analysis-error-code"] = inspectionDetail.error_code;
                }
            }
            inspectionDetail.analysis_result_data = JSON.stringify(detailResult);    
        }

        let headers = new Headers({ 'x-api-key': CommonConst.X_API_KEY });
        let options = new RequestOptions({ headers: headers});
        let body: any = {
            processKind: inspectionDetail.processKind,
            instruction_id: inspectionDetail.instruction_id,
            field_id: inspectionDetail.field_id,
            comment: inspectionDetail.comment,
            fe_infomation: inspectionDetail.fe_infomation,
            inspection_personnel: inspectionDetail.inspection_personnel,
            inspection_result: inspectionDetail.inspection_result,
            inspection_status: inspectionDetail.inspection_status,
            inspection_result_data: inspectionDetail.inspection_result_data,
            analysis_result_data: inspectionDetail.analysis_result_data,
            error_code: inspectionDetail.error_code
        };

        return this.http.put(CommonConst.API_URL_FIELDS,
        body,
        options
        ).map(
            response => {
                console.log("---------response--------");
                console.log(response);

                this.jsonResult = JSON.parse(response.text());

                console.log("Code = " + this.jsonResult.Code);
                console.log("Description = " + this.jsonResult.Description);

                if (this.jsonResult.Code != CommonConst.HTTP_STATUS_CODE_OK) {
                    this.executeResult = {
                        totalCount: null,
                        resultArray: null,
                        result: false,
                        message: "API Response  " + this.jsonResult.Code + ":" + this.jsonResult.Description
                    };  
                    return this.executeResult;      
                }
                this.executeResult = {
                    totalCount: null,
                    resultArray: null,
                    result: true,
                    message:"updateInspectionDetail success"
                };  
                return this.executeResult;    
            }
        ).catch (
            error => {
                console.log("inspection update error");
                return Observable.throw(error.statusText);
            }
        );
    }



}

import { Component, OnInit,  Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { CommonConst } from '../../constant/common-const';
import { MessageConst } from '../../constant/message-const';
import { DeliveryDetailConst } from '../../constant/delivery-detail-const';
import { DeliveryService } from '../../service/delivery.service';
import { DeliveryList } from '../../model/delivery-list';
import { DeliveryDetail } from '../../model/delivery-detail';
import { DeliveryDetailList } from '../../model/delivery-detail-list';
import { ExecuteResult } from '../../model/execute-result';
import { Http, Jsonp } from '@angular/http';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/delay'
import { InspectionService } from '../../service/inspection.service';
import { InspectionList } from '../../model/inspection-list';
import { InspectionCondition } from '../../model/inspection-condition';

declare var $;

@Component({
    selector: 'app-delivery-detail',
    templateUrl: './delivery-detail.component.html',
    styleUrls: ['./delivery-detail.component.css']
})
export class DeliveryDetailComponent implements OnInit, OnChanges{
    commonConst: CommonConst;
    deliveryDetailConst: DeliveryDetailConst;
    deliveryDetail: DeliveryDetail;
    deliveryDetailLists:DeliveryDetailList[];
    messageList: string[];
    updateBtnCtl: boolean;
    deliveryBtnCtl: boolean;
    successMessage: string;

    inspectionCondition: InspectionCondition;
    inspectionLists: InspectionList[];
    private getInspectionsResult: ExecuteResult; // inspectionList

    private getDeliveryDetailResult: ExecuteResult; // deliveryDetail
    private getDeliveryDetailListResult: ExecuteResult; // deliveryDetailFarmsList
    private updateDeliveryDetailResult: ExecuteResult; // deliveryDetail
  
    @Input() deliveryList: DeliveryList;
    @Output() eventKind = new EventEmitter<String>();

    constructor(private deliveryService: DeliveryService, private http: Http, jsonp:Jsonp, private inspectionService: InspectionService) {
        console.log("    DeliveryDetailComponent---------------constructor() -----------------");
        this.commonConst = CommonConst;
        this.deliveryDetailConst = DeliveryDetailConst;
        this.deliveryDetail = new DeliveryDetail();
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log("    DeliveryDetailComponent---------------ngOnChanges() -----------------");
    }

    ngOnInit() {
        console.log("    DeliveryDetailComponent---------------ngOnInit() --------------------");
        this.messageList = [""];
        this.updateBtnCtl = true;
        this.deliveryBtnCtl = true;
        this.successMessage = "";
    }

    getDeliveryDetail(deliveryList: DeliveryList) {
        console.log("    DeliveryDetailComponent---------------getDeliveryDetail() --------------");
        this.messageList = [""];
        this.deliveryService.getDeliveryDetail(deliveryList).subscribe(
            data => {
                this.getDeliveryDetailResult = data; 
                if(this.getDeliveryDetailResult.result) {
                    console.log("    DeliveryDetailComponent----getDeliveryDetail() success-------");
                    this.deliveryDetail = this.getDeliveryDetailResult.resultArray[0];
                    this.deliveryBtnCtl = true;
                    if(this.deliveryDetail.delivery_status == CommonConst.DELIVERY_STATUS_INSPECTABLE) {
                        this.deliveryBtnCtl = false;
                    }
                    // get list
                    this.inspectionCondition = new InspectionCondition();
                    this.inspectionCondition.instruction_id = deliveryList.instruction_id;
                    this.inspectionCondition.processKind = CommonConst.API_SEARCH_KIND_ALL_LIST;

                    this.inspectionService.getInspections(this.inspectionCondition).subscribe(
                        data => {
                            this.getInspectionsResult = data;
                            if(this.getInspectionsResult.result) {
                                console.log("    DeliveryDetailComponent----getInspections() success-------");
                                this.inspectionLists = this.getInspectionsResult.resultArray;
                                return;
                            } else {
                                console.log("    DeliveryDetailComponent----getInspections() error-------");
                                console.log(this.getInspectionsResult.message);
                                this.messageList.push(MessageConst.MODAL_DETAIL_SEARCH_NG);
                                return;
                            }
                        },
                        error => {
                            console.log("    DeliveryDetailComponent----getDeliveryDetail() error-------");
                            console.log(error);
                            this.messageList.push(MessageConst.MODAL_DETAIL_SEARCH_NG);
                            return;
                        }
                    );//getInspections subscribe end 

                } else {
                    console.log("    DeliveryDetailComponent--------getDeliveryDetail() error---------");
                    console.log(this.getDeliveryDetailResult.message);
                    this.messageList.push(MessageConst.MODAL_DETAIL_SEARCH_NG);
                    return;
                }
            },      
            error => {
                console.log("    DeliveryDetailComponent--------getDeliveryDetail() error----------");
                console.log(error);
                this.messageList.push(MessageConst.MODAL_DETAIL_SEARCH_NG);
                return;
            } 
        );//getDeliveryDetail subscribe end 
    }

    onChange() {
        console.log("    DeliveryDetailComponent---------------onChange() -----------------");
        // change update-button undisabled 
        this.updateBtnCtl = false;
        return;
    }

    updateComments() {
        console.log("    DeliveryDetailComponent---------------updateComments() -------------");
        this.messageList = [""];
        this.successMessage = "";
        // input check
        if(this.deliveryDetail.delivery_comment.length > 200) {
            this.messageList.push(MessageConst.DELIVERYDETAIL_OVERLENGTH_DELIVERY_COMMENT);
            return;
        }

        // update comments
        this.deliveryDetail.processKind = CommonConst.API_DELIVERY_UPDATE_KIND_UPDATE_COMMET;
        this.deliveryService.updateDeliveryDetail(this.deliveryDetail).subscribe(
            data => {
                this.updateDeliveryDetailResult = data; 
                if(this.updateDeliveryDetailResult.result) {
                    console.log("    DeliveryDetailComponent----updateDeliveryDetail() success-------");
                    this.successMessage = MessageConst.MODAL_DELIVERY_DETAIL_UPDATE_OK;
                    // change update-button disabled 
                    this.updateBtnCtl = true;
                    return;
                } else {
                    console.log("    DeliveryDetailComponent--------updateDeliveryDetail() error---------");
                    console.log(this.updateDeliveryDetailResult.message);
                    this.messageList.push(MessageConst.MODAL_INSPECTION_DETAIL_UPDATE_NG);
                    return;
                }
            },      
            error => {
                console.log("    DeliveryDetailComponent--------updateDeliveryDetail() error----------");
                console.log(error);
                this.messageList.push(MessageConst.MODAL_DELIVERY_DETAIL_UPDATE_NG);
                return;
            } 
        );
    }

    changeDeliveryStatus(eventKind: String) {
        console.log("    DeliveryDetailComponent------------changeDeliveryStatus() ---------");
        this.eventKind.emit(eventKind);
        this.deliveryDetailLists = null;
        this.inspectionLists = null;
        return;
    }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { JsonpModule } from '@angular/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './component/login/login.component';
import { InspectionListComponent } from './component/inspection-list/inspection-list.component';
import { InspectionDetailComponent } from './component/inspection-detail/inspection-detail.component';
import { DeliveryListComponent } from './component/delivery-list/delivery-list.component';
import { DeliveryDetailComponent } from './component/delivery-detail/delivery-detail.component';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { ChangepassComponent } from './component/changepass/changepass.component';
import { ResetpassComponent } from './component/resetpass/resetpass.component';
import { ProgressModalComponent } from './component/progress-modal/progress-modal.component';
import { jqxCalendarComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxcalendar';

import {CommonConst} from './constant/common-const';

import {LoginService} from './service/login.service';
import {InspectionService} from './service/inspection.service';
import {DeliveryService} from './service/delivery.service';
import { CognitoService } from './service/cognito.service';
import { SuccessModalComponent } from './component/success-modal/success-modal.component';
import { ErrorModalComponent } from './component/error-modal/error-modal.component';
import { ConfirmModalComponent } from './component/confirm-modal/confirm-modal.component';

//import { ExecuteResult } from './model/execute-result';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InspectionListComponent,
    InspectionDetailComponent,
    DeliveryListComponent,
    DeliveryDetailComponent,
    HeaderComponent,
    FooterComponent,
    ChangepassComponent,
    ResetpassComponent,
    jqxCalendarComponent,
    ProgressModalComponent,
    SuccessModalComponent,
    ErrorModalComponent,
    ConfirmModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    JsonpModule

  ],
  providers: [LoginService,InspectionService,DeliveryService,CognitoService],
  bootstrap: [AppComponent]
})
export class AppModule { }

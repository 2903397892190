import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SuccessModalConst } from '../../constant/success-modal-const';

@Component({
    selector: 'app-success-modal',
    templateUrl: './success-modal.component.html',
    styleUrls: ['./success-modal.component.css']
})
export class SuccessModalComponent implements OnInit {

    message: string;
    successModalConst: SuccessModalConst;
    @Output() successEventKind = new EventEmitter();

    constructor() {
        console.log("    SuccessModalComponent------------constructor() ----------------");
        this.successModalConst = SuccessModalConst;
    }

    ngOnInit() {
        console.log("    SuccessModalComponent------------ngOnInit() ----------------");
        this.message="success";
    }

    clickOk() {
        console.log("    SuccessModalComponent------------clickOk() ----------------");
        this.successEventKind.emit();
        return;
    }
    
    setScreenStatus(message: string) {
        console.log("    SuccessModalComponent------------setScreenStatus() ------------");
        this.message = message;
        return;
    }
}

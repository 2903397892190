import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonConst } from '../../constant/common-const';
import { MessageConst } from '../../constant/message-const';
import { InspectionListConst } from '../../constant/inspection-list-const';
import { InspectionService } from '../../service/inspection.service';
import { InspectionList } from '../../model/inspection-list';
import { InspectionCondition } from '../../model/inspection-condition';
import { InspectionDetailComponent } from '../inspection-detail/inspection-detail.component';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { InspectionDetail } from '../../model/inspection-detail';
import { ExecuteResult } from '../../model/execute-result';
import { CognitoService } from '../../service/cognito.service';

declare var $;

@Component({
    selector: 'app-inspection-list',
    providers: [InspectionService],
    templateUrl: './inspection-list.component.html',
    styleUrls: ['./inspection-list.component.css']
})

export class InspectionListComponent implements OnInit  {
    commonConst: CommonConst;
    inspectionListConst:InspectionListConst
    inspectionCondition: InspectionCondition;
    fixInspectionCondition: InspectionCondition;
    inspectionLists: InspectionList[];
    selectedInspectionList: InspectionList
    inspectionDetail: InspectionDetail;
    messageList: string[];

    private getInspectionsResult: ExecuteResult; // inspectionList
    private getInspectionTotalCountResult: ExecuteResult; // inspectionListTotalCount
    private getInspectionDetailResult: ExecuteResult; // inspectionDetail
    private updateInspectionDetailResult: ExecuteResult; // inspectionDetail
    
    @ViewChild(InspectionDetailComponent)
    protected inspectionDetailComponent: InspectionDetailComponent;
    @ViewChild(SuccessModalComponent)
    protected successModalComponent: SuccessModalComponent;
    @ViewChild(ErrorModalComponent)
    protected errorModalComponent: ErrorModalComponent;

    totalCount: number;
    serchBtnCtl: boolean;
    prevBtnCtl: boolean;
    nextBtnCtl: boolean;
    username: string;
    result: string;

    constructor(private router: Router, private inspectionservice: InspectionService, private cognito: CognitoService) {
        console.log("InspectionListComponent------------constructor() -------------");
        this.commonConst = CommonConst;
        this.inspectionListConst = InspectionListConst;
        this.inspectionCondition = new InspectionCondition();
        this.cognito.isAuthenticated()
        .then((res) => {
            this.username = res["idToken"]["payload"]["cognito:username"];
        })
        .catch((err) => {
            console.log(err);
            this.router.navigate(['login']);
        });
    }

    ngOnInit() {
        console.log("InspectionListComponent------------ngOnInit() -------------");
        // initial condition value
        this.clearCondition();
        this.prevBtnCtl = true;
        this.nextBtnCtl = false;
        // initial search
        this.getInspectionLists();
    }

    // tabSelect
    pageSelect() {
        console.log("InspectionListComponent------------pageSelect() -------------");
        this.router.navigate(['delivery']);
        return;
    }

    getInspectionLists(){
        console.log("InspectionListComponent------------getInspectionLists() -------------");
        this.showLoadingModal();
        this.messageList = [""];
        // input check
        if(this.inspectionCondition.receptionDateFrom != "") {
            if(!this.inspectionCondition.receptionDateFrom.match(/^\d{4}-\d{2}-\d{2}$/)) {
                this.messageList.push(MessageConst.DELIVERYLIST_INVALIDFORMT_RECEPTDATE_FROM);
            }
        }
        if(this.inspectionCondition.receptionDateTo != "") {
            if(!this.inspectionCondition.receptionDateTo.match(/^\d{4}-\d{2}-\d{2}$/)) {
                this.messageList.push(MessageConst.DELIVERYLIST_INVALIDFORMT_RECEPTDATE_TO);
            }
        }
        if(this.messageList.length > 1) {
            this.hideLoadingModal();
            return;
        }
        this.fixInspectionCondition = Object.assign({}, this.inspectionCondition);    
        this.fixInspectionCondition.startLineNumber = 1;
        this.fixInspectionCondition.endLineNumber = 20;
        this.fixInspectionCondition.page = 1;
        this.fixInspectionCondition.processKind = CommonConst.API_SEARCH_KIND_LIST;

        // get list
        this.inspectionservice.getInspections(this.fixInspectionCondition).subscribe(
            data => {
                this.getInspectionsResult = data;
                if(this.getInspectionsResult.result) {
                    this.inspectionLists = this.getInspectionsResult.resultArray;

                    this.inspectionservice.getInspectionsTotalCount(this.fixInspectionCondition).subscribe(
                        data => {
                            console.log("InspectionListComponent-------getInspectionsTotalCount() success---------");
                            this.getInspectionTotalCountResult = data; 
                            if(this.getInspectionTotalCountResult.result) {
                                this.totalCount = +this.getInspectionTotalCountResult.totalCount;

                                this.fixInspectionCondition.startLineNumber = 1;
                                this.fixInspectionCondition.endLineNumber = 20;

                                if(this.totalCount < 21) {
                                    this.nextBtnCtl = true;
                                    this.fixInspectionCondition.endLineNumber = this.totalCount;
                                }
                                if(this.totalCount > 20) {
                                    this.nextBtnCtl = false;
                                    let arrayCount = Math.ceil(this.totalCount/20); 
                                    this.fixInspectionCondition.instructionIdKeyList = new Array(arrayCount);
                                    this.fixInspectionCondition.fieldIdKeylist = new Array(arrayCount);                  
                                    this.fixInspectionCondition.instructionIdKeyList[0] = this.getInspectionsResult.resultArray[19].instruction_id;
                                    this.fixInspectionCondition.fieldIdKeylist[0] = this.getInspectionsResult.resultArray[19].field_id;
                                }
                                if(this.totalCount == 0) {
                                    this.nextBtnCtl = true;
                                    this.prevBtnCtl = true;
                                    this.fixInspectionCondition.startLineNumber = this.totalCount;
                                    this.fixInspectionCondition.endLineNumber = this.totalCount;
                                }
                                this.serchBtnCtl = false;
                                this.hideLoadingModal();
                                return;
                            } else {
                                this.hideLoadingModal();
                                this.showErrorModal();
                                this.errorModalComponent.setScreenStatus(MessageConst.MODAL_SEARCH_TOTALCOUNT_NG);
                                return;
                            }
                        },
                        error => {
                            console.log("InspectionListComponent------------getInspectionsTotalCount() error-------------");
                            console.log(error);
                            this.hideLoadingModal();
                            this.errorModalComponent.setScreenStatus(MessageConst.MODAL_SEARCH_TOTALCOUNT_NG);
                            this.showErrorModal();
                            return;
                        }      
                    );
                } else {
                    console.log(this.getInspectionsResult.message);
                    this.hideLoadingModal();
                    this.errorModalComponent.setScreenStatus(MessageConst.MODAL_SEARCH_NG);
                    this.showErrorModal();
                    return;
                }
            },
            error => {
                console.log("InspectionListComponent------------getInspections() error-------------");
                console.log(error);
                this.hideLoadingModal();
                this.errorModalComponent.setScreenStatus(MessageConst.MODAL_SEARCH_NG);
                this.showErrorModal();
                return;
            }
        );
    }

    getInspectionPageLists(){
        console.log("InspectionListComponent------------getInspectionPageLists() -------------");

        // get pagelist
        this.inspectionservice.getPageInspections(this.fixInspectionCondition).subscribe(
            data => {
                this.getInspectionsResult = data;
                if(this.getInspectionsResult.result) {
                    this.inspectionLists = this.getInspectionsResult.resultArray;
                    if(this.getInspectionsResult.resultArray.length == 20) {
                        this.fixInspectionCondition.instructionIdKeyList[this.fixInspectionCondition.page - 1] = this.getInspectionsResult.resultArray[19].instruction_id;
                        this.fixInspectionCondition.fieldIdKeylist[this.fixInspectionCondition.page - 1] = this.getInspectionsResult.resultArray[19].field_id;
                    }
                    this.hideLoadingModal();
                    return;
                } else {
                    console.log(this.getInspectionsResult.message);
                    this.hideLoadingModal();
                    this.errorModalComponent.setScreenStatus(MessageConst.MODAL_SEARCH_NG);
                    this.showErrorModal();
                    return;
                }
            },
            error => {
                console.log("InspectionListComponent------------getInspectionPageLists() error-------------");
                console.log(error);
                this.hideLoadingModal();
                this.errorModalComponent.setScreenStatus(MessageConst.MODAL_SEARCH_NG);
                this.showErrorModal();
                return;
            }
        );
    }

    pageNext(){
        console.log("InspectionListComponent------------pageNext() -------------");
        this.showLoadingModal();
        this.fixInspectionCondition.start_instruction_id = this.fixInspectionCondition.instructionIdKeyList[this.fixInspectionCondition.page-1];
        this.fixInspectionCondition.start_field_id = this.fixInspectionCondition.fieldIdKeylist[this.fixInspectionCondition.page-1];
        this.fixInspectionCondition.page = this.fixInspectionCondition.page + 1;
        this.fixInspectionCondition.startLineNumber = this.fixInspectionCondition.startLineNumber + 20;
        this.fixInspectionCondition.endLineNumber = this.fixInspectionCondition.endLineNumber + 20;

        this.prevBtnCtl = false;

        let diffCount = this.totalCount - this.fixInspectionCondition.endLineNumber;
        if( diffCount <= 0 ) {
            this.nextBtnCtl = true;
            this.fixInspectionCondition.endLineNumber = this.fixInspectionCondition.endLineNumber + diffCount;
        }
        this.getInspectionPageLists();
        return;
    }

    pagePrev(){
        console.log("InspectionListComponent------------pagePrev() -------------");
        this.showLoadingModal();
        this.fixInspectionCondition.start_instruction_id = "";
        this.fixInspectionCondition.start_field_id = "";
        if (3 <= this.fixInspectionCondition.page ) {
            this.fixInspectionCondition.start_instruction_id = this.fixInspectionCondition.instructionIdKeyList[this.fixInspectionCondition.page-3];
            this.fixInspectionCondition.start_field_id = this.fixInspectionCondition.fieldIdKeylist[this.fixInspectionCondition.page-3];  
        }
        this.fixInspectionCondition.page = this.fixInspectionCondition.page - 1;
        this.fixInspectionCondition.startLineNumber = this.fixInspectionCondition.startLineNumber - 20;
        this.fixInspectionCondition.endLineNumber = this.fixInspectionCondition.startLineNumber + 19;

        this.nextBtnCtl = false;      
        if(this.fixInspectionCondition.startLineNumber == 1) {
            this.prevBtnCtl = true;
        } else {
            this.prevBtnCtl = false;      
        }
        this.getInspectionPageLists();
        return;
    }  

    clearCondition() {

        console.log("InspectionListComponent------------clearCondition() -------------");
        // initial condition value
        this.inspectionCondition.owner_id = "";
        this.inspectionCondition.instruction_id = "";
        this.inspectionCondition.receptionDateFrom = "";
        this.inspectionCondition.receptionDateTo = "";
        this.inspectionCondition.inspectionStatusAnalysis = false;
        this.inspectionCondition.inspectionStatusAnalysisError = true;
        this.inspectionCondition.inspectionStatusInspectionWait = true;
        this.inspectionCondition.inspectionStatusModifyImageWait = true;
        this.inspectionCondition.inspectionStatusInspectionComplete = false;
        this.inspectionCondition.inspectionStatusStop = false;
        this.inspectionCondition.inspectionStatusDeliveryError = true;
        this.inspectionCondition.inspectionResultNotInspection = true;
        this.inspectionCondition.inspectionResultPending = true;
        this.inspectionCondition.inspectionResultNecessaryModify = true;
        this.inspectionCondition.inspectionResultOk = false;
        this.inspectionCondition.inspectionResultNg = false;
        this.inspectionCondition.inspection_personnel = "";
        this.inspectionCondition.comment = "";
        this.messageList = [""];
        this.serchBtnCtl = false;
        return;
    }

    changeCondition() {
        console.log("InspectionListComponent------------changeCondition() -------------");
        this.serchBtnCtl = false;    
        return;
    }

    selectRow(inspectionList: InspectionList) {
        console.log("InspectionListComponent------------selectRow() -------------");
        this.selectedInspectionList = inspectionList;
        this.inspectionDetailComponent.getInspectionDetail(inspectionList);
        return;
    }

    // callBack from Detail Screen
    onDetailEvent(eventKind: string) {
        console.log("InspectionListComponent--------onDetailEvent() -----------");
        if(eventKind != CommonConst.CROSS_BUTTON) {
            this.showLoadingModal();
            // getInspection Detail
            this.inspectionservice.getInspectionDetail(this.selectedInspectionList).subscribe(
                data => {
                    this.getInspectionDetailResult = data
                    if(this.getInspectionDetailResult.result) {
                        console.log("InspectionListComponent------getInspectionDetail() success---------");
                        this.inspectionDetail = this.getInspectionDetailResult.resultArray[0];

                        // update inspection result
                        this.inspectionDetail.inspection_result = eventKind;
                        this.inspectionDetail.inspection_personnel = this.username;
                        
                        this.inspectionDetail.processKind = CommonConst.API_INSPECTION_UPDATE_KIND_UPDATE_STATUS;
                        if(eventKind == CommonConst.INSPECTION_RESULT_OK || eventKind == CommonConst.INSPECTION_RESULT_NG) {
                            this.inspectionDetail.inspection_status = CommonConst.INSPECTION_STATUS_INSPECTED;
                        } else if (eventKind == CommonConst.INSPECTION_RESULT_MODIFY) {
                            this.inspectionDetail.inspection_status = CommonConst.INSPECTION_STATUS_MODIFY;
                        }

                        this.inspectionservice.updateInspectionDetail(this.inspectionDetail).subscribe(
                            data => {
                                this.getInspectionDetailResult = data; 
                                if(this.getInspectionDetailResult.result) {
                                    console.log("InspectionListComponent----updateInspectionDetail() success-------");
                                    let replaceString: string;
                                    for (let inspectionResult of CommonConst.INSPECTION_RESULT_LIST) {
                                      if (inspectionResult["key"] == eventKind) {
                                        replaceString = inspectionResult["value"];
                                        break;
                                      }
                                    } 
                                    let successMessage = MessageConst.MODAL_INSPECTION_OK.replace(/REPLACESTRING/g , replaceString);
                                    this.hideLoadingModal();
                                    this.successModalComponent.setScreenStatus(successMessage);          
                                    this.showSuccessModal();
                                    return;
                                } else {
                                    console.log("InspectionListComponent--------updateInspectionDetail() error---------");
                                    console.log(this.getInspectionDetailResult.message);
                                    this.hideLoadingModal();
                                    this.errorModalComponent.setScreenStatus(MessageConst.MODAL_INSPECTION_NG);
                                    this.showErrorModal();
                                    return;
                                }
                            },      
                            error => {
                                console.log("InspectionListComponent--------updateInspectionDetail() error----------");
                                console.log(error);
                                this.hideLoadingModal();
                                this.errorModalComponent.setScreenStatus(MessageConst.MODAL_INSPECTION_NG);
                                this.showErrorModal();
                                return;
                            } 
                        );// update inspection result end 

                    } else {
                        console.log("InspectionListComponent--------getInspectionDetail() error-----------");
                        console.log(this.getInspectionDetailResult.message);
                        this.hideLoadingModal();
                        this.errorModalComponent.setScreenStatus(MessageConst.MODAL_DETAIL_SEARCH_NG);
                        this.showErrorModal();
                        return;
                    }
                },      
                error => {
                    console.log("InspectionListComponent--------getInspectionDetail() error-----------");
                    console.log(error);
                    this.hideLoadingModal();
                    this.errorModalComponent.setScreenStatus(MessageConst.MODAL_DETAIL_SEARCH_NG);
                    this.showErrorModal();
                    return;
                } 
            ); // get inspection detail end
        } else { 
            // cross button
            return;
        }
    }

    onSuccessModalEvent() {
        console.log("InspectionListComponent------------onSuccessModalEvent() -------------");
        this.hideSuccessModal();
        // re-search
        this.getInspectionLists();    
        return;
    }
    onErrorModalEvent() {
        console.log("InspectionListComponent------------onErrorModalEvent() -------------");
        this.hideErrorModal();
        return;
    }
    showSuccessModal() {
        console.log("InspectionListComponent------------showSuccessModal() -------------");
        $('#successModal').modal('show');
        return;
    }
    hideSuccessModal() {
        console.log("InspectionListComponent------------hideSuccessModal() -------------");
        $('#successModal').modal('hide');
        return;
    }
    showErrorModal() {
        console.log("InspectionListComponent------------showErrorModal() -------------");
        $('#errorModal').modal('show');
        return;
    }
    hideErrorModal() {
        console.log("InspectionListComponent------------hideErrorModal() -------------");
        $('#errorModal').modal('hide');
        return;
    }

    showLoadingModal() {
        console.log("InspectionListComponent------------showLoadingModal() -------------");
        $('#modalLoad').modal('show');
        return;
    }
    hideLoadingModal() {
        console.log("InspectionListComponent------------hideLoadingModal() -------------");
        $('#modalLoad').modal('hide');
        return;
    }
    
}

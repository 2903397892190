export class InspectionCondition {
    // schema valiable
    instruction_id: string;
    start_instruction_id: string;
    start_field_id: string;
    owner_id: string;

    // internal valiable
    processKind: string;
    page: number;
    startLineNumber: number;
    endLineNumber: number;
    receptionDateFrom: string;
    receptionDateTo: string;
    inspectionStatusAnalysis: boolean;
    inspectionStatusAnalysisError: boolean;
    inspectionStatusInspectionWait: boolean;
    inspectionStatusModifyImageWait: boolean;
    inspectionStatusInspectionComplete: boolean;
    inspectionStatusStop: boolean;
    inspectionStatusDeliveryError: boolean;
    inspectionResultNotInspection: boolean;
    inspectionResultPending: boolean;
    inspectionResultNecessaryModify: boolean;
    inspectionResultOk: boolean;
    inspectionResultNg: boolean;
    inspection_personnel: string;
    comment: string;
    instructionIdKeyList: string[];
    fieldIdKeylist: string[];
}
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ResetPassConst } from '../../constant/resetpass-const';
import { MessageConst } from '../../constant/message-const';
import { LoginService } from '../../service/login.service';
import { ExecuteResult } from '../../model/execute-result';

@Component({
  selector: 'app-resetpass',
  templateUrl: './resetpass.component.html',
  styleUrls: ['./resetpass.component.css']
})
export class ResetpassComponent implements OnInit {
  resetPassConst: ResetPassConst;

  messageList: string[];
  loginId:string;
  private sendMailResult:    ExecuteResult;
  @Output() eventKind = new EventEmitter<String>();

  constructor(private loginservice: LoginService) {
    console.log("    ResetpassComponent-------------constructor() ------------");
    this.resetPassConst = ResetPassConst;
   }

  ngOnInit() {
    console.log("    ResetpassComponent-------------ngOnInit() ------------");
    this.loginId = "";
    this.messageList = [""];
  }
  sendMail() {
    console.log("    ResetpassComponent-------------sendMail() ------------");
    this.messageList = [""];
    // input check
    if(this.loginId ==""){
      this.messageList.push(MessageConst.LOGIN_NO_LOGINID);
      return;
    }
    
    // send Email
    this.loginservice.sendPasswordReset(this.loginId)
        .then((result) => {
      this.eventKind.emit("sendMail!");
        }).catch((err) => {
            this.messageList.push(err.additionalMessage.message);
        });
    return;
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmModalConst } from '../../constant/confirm-modal-const';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

    message: string;
    confirmModalConst: ConfirmModalConst
    @Output() confirmEventKind = new EventEmitter();

    constructor() {
        console.log("    ConfirmModalComponent------------constructor() ----------------");
        this.confirmModalConst = ConfirmModalConst;
    }

    ngOnInit() {
        console.log("    ConfirmModalComponent------------ngOnInit() ----------------");
        this.message="confirm";
    }

    clickOk() {
        console.log("    ConfirmModalComponent------------clickOk() ----------------");
        this.confirmEventKind.emit("Ok");
        return;
    }

    clickCancel() {
        console.log("    ConfirmModalComponent------------clickCancel() ----------------");
        this.confirmEventKind.emit("Cancel");
        return;    
    }
    
    setScreenStatus(message: string) {
        console.log("    ConfirmModalComponent------------setScreenStatus() ------------");
        this.message = message;
        return;
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ChangePassConst } from '../../constant/changepass-const';
import { MessageConst } from '../../constant/message-const';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { LoginService } from '../../service/login.service';
import { ExecuteResult } from '../../model/execute-result';
import { CognitoService } from '../../service/cognito.service';

declare var $;

@Component({
    selector: 'app-changepass',
    templateUrl: './changepass.component.html',
    styleUrls: ['./changepass.component.css']
})
export class ChangepassComponent implements OnInit {
    changePassConst: ChangePassConst;
    userIdInput:string;
    oldPasswordInput:string;
    newPasswordInput:string;
    newPasswordReInput:string;
    messageList: string[];

    @ViewChild(SuccessModalComponent)
    protected successModalComponent: SuccessModalComponent;
    @ViewChild(ErrorModalComponent)
    protected errorModalComponent: ErrorModalComponent;

    private setPasswordResult:        ExecuteResult;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private loginservice: LoginService, private cognito: CognitoService) {
        console.log("ChangepassComponent----------------constructor() --------------");
        this.changePassConst = ChangePassConst;
    }

    ngOnInit() {
        console.log("ChangepassComponent----------------ngOnInit() --------------");
        // initial form value
        this.userIdInput = "";
        this.oldPasswordInput = "";
        this.newPasswordInput = "";
        this.newPasswordReInput = "";
        this.messageList = [""];
    }
    changePassword() {
        console.log("ChangepassComponent----------------changePassword() --------------");
        this.messageList = [""];
        // input check
        if(this.userIdInput =="") {
            this.messageList.push(MessageConst.LOGIN_NO_LOGINID);
        }
        if(this.oldPasswordInput =="") {
            this.messageList.push(MessageConst.CHANGEPASS_NO_COMFIRM_KEY);
        }
        if(this.newPasswordInput =="" && this.newPasswordReInput =="") {
            this.messageList.push(MessageConst.CHANGEPASS_NO_NEWPASSWORD);
        } else {
            if(this.newPasswordInput =="") {
               this.messageList.push(MessageConst.CHANGEPASS_NO_NEWPASSWORD);
            }
            if(this.newPasswordReInput =="") {
                this.messageList.push(MessageConst.CHANGEPASS_NO_REINPUTNEWPASSWORD);
            }          
        }
        if(this.messageList.length > 1) {
            return;
        }

        if (this.newPasswordInput.length < 6 || !this.newPasswordInput.match(/^[a-zA-Z0-9 -/:-@\[-\`\{-\~]+$/)) {
              this.messageList.push(MessageConst.CHANGEPASS_INVALIDFORMAT_NEWPASSWORD);
        }
        if(this.messageList.length > 1) {
            return;
        }
        if (this.newPasswordInput != this.newPasswordReInput) {
              this.messageList.push(MessageConst.CHANGEPASS_UNMATCHFORMAT_NEWPASSWORD);
        }
        if(this.messageList.length > 1) {
           return;
        }

        // change password
        this.loginservice.changePassword(this.userIdInput, String(this.oldPasswordInput), this.newPasswordInput)
            .then((result) => {
                this.showSuccessModal();
                this.successModalComponent.setScreenStatus(MessageConst.CHANGEPASS_OK);
            }).catch((err) => {
                this.messageList.push(err.additionalMessage.message);
        });
    }

    onSuccessModalEvent() {
        console.log("ChangepassComponent------------onSuccessModalEvent() -------------");
        this.hideSuccessModal();
        // Transition to login screen
        this.router.navigate(['login']);
        return;
    }
    showSuccessModal() {
        console.log("ChangepassComponent------------showSuccessModal() -------------");
        $('#successModal').modal('show');
        return;
    }
    hideSuccessModal() {
        console.log("ChangepassComponent------------hideSuccessModal() -------------");
        $('#successModal').modal('hide');
        return;
    }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { CommonConst } from '../../constant/common-const';
import { MessageConst } from '../../constant/message-const';

import { LoginConst } from '../../constant/login-const';
import { LoginService } from '../../service/login.service';
import { ExecuteResult } from '../../model/execute-result';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { HeaderComponent } from '../header/header.component';
import { CognitoService } from '../../service/cognito.service';


declare var $;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginConst: LoginConst;

    loginId: string;
    passWord: string;
    messageList: string[];

    @ViewChild(SuccessModalComponent)
    protected successModalComponent: SuccessModalComponent;
    @ViewChild(ErrorModalComponent)
    protected errorModalComponent: ErrorModalComponent;

    private confirmLoginResult: ExecuteResult;
    private loginResult:        ExecuteResult;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private loginservice: LoginService, private cognito: CognitoService) {
        console.log("LoginComponent------------constructor() -------------");
        this.loginConst = LoginConst;
        this.cognito.isAuthenticated()
        .then((res) => {
            this.router.navigate(['inspection']);
        })
        .catch((err) => {
            console.log(err);
            this.router.navigate(['login']);
        });
    }

    ngOnInit() {
        console.log("LoginComponent------------ngOnInit() -------------");
        // initial form value
        this.loginId = "";
        this.passWord = "";
        this.messageList = [""];

        // check query
        this.activatedRoute.queryParams.forEach((params: Params) => {
            if(params['logintype'] == 'changepass') {
                // Transition to Password change screen
                this.router.navigate(['changepass']);
            }
        }); 

        // confirm login
        this.confirmLoginResult = this.loginservice.confirmLogin();
        if(this.confirmLoginResult.result){
            // Transition to inspection list screen
            this.router.navigate(['inspection']);  
        }
    }

    login(){
      console.log("LoginComponent------------login() -------------");
      this.messageList = [""];
      // input check
      if(this.loginId ==""){
          this.messageList.push(MessageConst.LOGIN_NO_LOGINID);
      }
      if(this.passWord ==""){
          this.messageList.push(MessageConst.LOGIN_NO_PASSWORD);
      }
      if(this.messageList.length > 1) {
          return;
      }

      this.loginservice.login(this.loginId,this.passWord)
          .then((result) => {
              this.router.navigate(['inspection']);  
          }).catch((err) => {
              this.messageList.push(err.additionalMessage.message);
          });
    }

    onResetPassEvent(eventKind: string) {
        console.log("LoginComponent------------onDetailEvent() -------------");
        this.hideResetPassModal();
        this.successModalComponent.setScreenStatus(MessageConst.RESET_PASS_SUCCESS);
        this.showSuccessModal();
        return;
    }
    onSuccessModalEvent() {
        console.log("LoginComponent------------onSuccessModalEvent() -------------");
        this.hideSuccessModal();
        return;
    }
    hideResetPassModal() {
        console.log("LoginComponent------------hideResetPassModal() -------------");
        $('#modalpasswordReset').modal('hide');
        return;
    }
    showSuccessModal() {
        console.log("LoginComponent------------showSuccessModal() -------------");
        $('#successModal').modal('show');
        return;
    }
    hideSuccessModal() {
        console.log("LoginComponent------------hideSuccessModal() -------------");
        $('#successModal').modal('hide');
        return;
    }
    showErrorModal() {
        console.log("LoginComponent------------showErrorModal() -------------");
        $('#errorModal').modal('show');
        return;
    }
    hideErrorModal() {
        console.log("LoginComponent------------hideErrorModal() -------------");
        $('#errorModal').modal('hide');
        return;
    }
}

import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { MessageConst } from '../../constant/message-const';
import { ErrorModalConst } from '../../constant/error-modal-const';

@Component({
    selector: 'app-error-modal',
    templateUrl: './error-modal.component.html',
    styleUrls: ['./error-modal.component.css']
})
export class ErrorModalComponent implements OnInit {
    message: string;
    errorModalConst: ErrorModalConst;
    @Output() errorEventKind = new EventEmitter<String>();

    constructor() {
        console.log("    ErrorModalComponent------------constructor() --------------");
        this.errorModalConst = ErrorModalConst;
    }

    ngOnInit() {
        console.log("    ErrorModalComponent------------ngOnInit() ----------------");
        this.message=MessageConst.MODAL_INITIAL_NG;
    }

    clickOk() {
        console.log("    ErrorModalComponent------------clickOk() ----------------");
        this.errorEventKind.emit();
        return;
    }
    setScreenStatus(message: string) {
        console.log("    ErrorModalComponent------------setScreenStatus() ------------");
        this.message = message;
        return;
    }

}

export class DeliveryDetail {
    // schema valiable
    instruction_id: string;
    owner_id: string;
    delivery_status: string;
    delivery_status_display: string;
    delivery_personnel: string;
    request_date: string;
    delivery_comment: string;
    limit_date: string;
    type: string;
    delivery_date: string;
    update_date: string;
    delivery_enable: string;

    // internal valiable
    processKind: string;
}
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommonConst } from '../../constant/common-const';
import { MessageConst } from '../../constant/message-const';
import { InspectionDetailConst } from '../../constant/inspection-detail-const';
import { InspectionService } from '../../service/inspection.service';
import { InspectionList } from '../../model/inspection-list';
import { InspectionDetail } from '../../model/inspection-detail';
import { ExecuteResult } from '../../model/execute-result';

declare var $;

@Component({
    selector: 'app-inspection-detail',
    templateUrl: './inspection-detail.component.html',
    styleUrls: ['./inspection-detail.component.css']
})

export class InspectionDetailComponent implements OnInit {
    commonConst: CommonConst;
    inspectionDetailConst: InspectionDetailConst;
    inspectionDetail: InspectionDetail;
    messageList: string[];
    updateBtnCtl: boolean;
    okBtnCtl: boolean;
    pendingBtnCtl: boolean;
    ngBtnCtl: boolean;
    fixBtnCtl: boolean;
    successMessage: string;

    private getInspectionDetailResult: ExecuteResult; // inspectionDetail
    private updateCommentsResult: ExecuteResult; // updateComments
    
    @Input() inspectionList: InspectionList;
    @Output() eventKind = new EventEmitter<String>();

    constructor(private inspectionservice: InspectionService) {
        console.log("    InspectionDetailComponent-----------constructor() ----------------------");
        this.commonConst = CommonConst;
        this.inspectionDetailConst = InspectionDetailConst;
        this.inspectionDetail = new InspectionDetail();
    }

    ngOnInit() {
        console.log("    InspectionDetailComponent-----------ngOnInit() ----------------------");
        this.messageList = [""];
        this.updateBtnCtl = true;
        this.successMessage = "";
    }

    getInspectionDetail(inspectionList: InspectionList) {
        console.log("    InspectionDetailComponent-------getInspectionDetail() --------");
        this.messageList = [""];
        this.successMessage = "";
        this.inspectionservice.getInspectionDetail(inspectionList).subscribe(
            data => {
                console.log("    InspectionDetailComponent----getInspectionDetail() success-------");
                this.getInspectionDetailResult = data; 
                if(this.getInspectionDetailResult.result) {
                    this.inspectionDetail = this.getInspectionDetailResult.resultArray[0];
                    this.okBtnCtl = false;
                    this.pendingBtnCtl = false;
                    this.ngBtnCtl = false;
                    this.fixBtnCtl = false;
                    if(this.inspectionDetail.inspection_result == CommonConst.INSPECTION_RESULT_OK) {
                        this.okBtnCtl = true;
                    } else if(this.inspectionDetail.inspection_result == CommonConst.INSPECTION_RESULT_RESERVATION) {
                        this.pendingBtnCtl = true;
                    } else if(this.inspectionDetail.inspection_result == CommonConst.INSPECTION_RESULT_NG) {
                        this.ngBtnCtl = true;
                    } else if(this.inspectionDetail.inspection_result == CommonConst.INSPECTION_RESULT_MODIFY) {
                        this.fixBtnCtl = true;                        
                    }
                    
                    // average check
                    if(this.inspectionDetail.ndvi_average == CommonConst.OK_BTN_CTL_AVERAGE_THRESHOLD_1 || this.inspectionDetail.ndvi_average == CommonConst.OK_BTN_CTL_AVERAGE_THRESHOLD_2 
                        || this.inspectionDetail.skin_grafting_rate_average == CommonConst.OK_BTN_CTL_AVERAGE_THRESHOLD_1 || this.inspectionDetail.skin_grafting_rate_average == CommonConst.OK_BTN_CTL_AVERAGE_THRESHOLD_2
                        || this.inspectionDetail.ndvix_average == CommonConst.OK_BTN_CTL_AVERAGE_THRESHOLD_1 || this.inspectionDetail.ndvix_average == CommonConst.OK_BTN_CTL_AVERAGE_THRESHOLD_2) {
                            this.okBtnCtl = true;
                    }
                    return;
                } else {
                    console.log("    InspectionDetailComponent--------getInspectionDetail() error---------");
                    console.log(this.getInspectionDetailResult.message);
                    this.messageList.push(MessageConst.MODAL_DETAIL_SEARCH_NG);
                    return;
                }
            },      
            error => {
                console.log("    ★InspectionDetailComponent--------getInspectionDetail() error----------");
                console.log(error);
                this.messageList.push(MessageConst.MODAL_DETAIL_SEARCH_NG);
                return;
            } 
        );
    }

    onChange() {
        console.log("    InspectionDetailComponent-----------onChange() ------------------");
        // change update-button undisabled 
        this.updateBtnCtl = false;
    }

    updateComments() {
        console.log("    InspectionDetailComponent-----------updateComments() ----------------");
        this.messageList = [""];
        this.successMessage = "";
        // input check
        if(this.inspectionDetail.comment_display.length > 200) {
            this.messageList.push(MessageConst.DELIVERYDETAIL_OVERLENGTH_COMMENT);
        }

        let strErrorcode: String = String(this.inspectionDetail.fe_infomation_display);

        if(strErrorcode.length > 200) {
            this.messageList.push(MessageConst.DELIVERYDETAIL_OVERLENGTH_FEINFO);
        }
        if(this.messageList.length > 1) {
            return;
        }

        if(this.inspectionDetail.comment_display.length == 0) {
            this.inspectionDetail.comment = CommonConst.EMPTY_TEXT_DB;
        } else {
            this.inspectionDetail.comment = this.inspectionDetail.comment_display;
        }

        if(strErrorcode.length == 0) {
            this.inspectionDetail.fe_infomation = CommonConst.EMPTY_TEXT_DB;
            this.inspectionDetail.error_code = CommonConst.EMPTY_TEXT_DB;
        } else {
            this.inspectionDetail.fe_infomation = this.inspectionDetail.fe_infomation_display;
            this.inspectionDetail.error_code = this.inspectionDetail.fe_infomation_display;
        }

        // update comments
        this.inspectionDetail.processKind = CommonConst.API_INSPECTION_UPDATE_KIND_UPDATE_COMMET;
        this.inspectionservice.updateInspectionDetail(this.inspectionDetail).subscribe(
          data => {
              this.getInspectionDetailResult = data; 
              if(this.getInspectionDetailResult.result) {
                  console.log("    InspectionDetailComponent----updateInspectionDetail() success-------");
                  this.successMessage = MessageConst.MODAL_INSPECTION_DETAIL_UPDATE_OK;
                  // change update-button disabled 
                  this.updateBtnCtl = true;
                  return;
              } else {
                  console.log("    InspectionDetailComponent--------updateInspectionDetail() error---------");
                  console.log(this.getInspectionDetailResult.message);
                  this.messageList.push(MessageConst.MODAL_INSPECTION_DETAIL_UPDATE_NG);
                  return;
              }
          },      
          error => {
              console.log("    InspectionDetailComponent--------updateInspectionDetail() error----------");
              console.log(error);
              this.messageList.push(MessageConst.MODAL_INSPECTION_DETAIL_UPDATE_NG);
              return;
          } 
        );
    }

    changeInspectionResult(eventKind: String) {
        console.log("    InspectionDetailComponent-----------changeInspectionResult() ----------------");
        this.messageList = [""];
        // return push-button-Kind to parentScreen
        this.eventKind.emit(eventKind);
        this.clearDetail();
        return;
    }

    clearDetail() {
        this.inspectionDetail.inspection_result_display = "";
        this.inspectionDetail.inspection_status_display = "";
        this.inspectionDetail.inspection_personnel_display = "";
        this.inspectionDetail.skin_grafting_rate_path = "";
        this.inspectionDetail.ndvi_path = "";
        this.inspectionDetail.ndvix_path = "";
        this.inspectionDetail.visual_path = "";
    }
}

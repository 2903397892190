export class InspectionDetail {

    // schema valiable
    instruction_id: string;
    field_id: string;
    owner_id: string;
    inspection_status: string;
    inspection_status_display: string;
    inspection_result: string;
    inspection_result_display: string;
    inspection_personnel: string;
    inspection_personnel_display: string;
    request_date: string;
    comment: string;
    comment_display: string;
    fe_infomation: string;
    fe_infomation_display: string;
    ndvi_average: string;
    ndvi_variation: string;
    ndvi_cover_rate: string;
    skin_grafting_rate_average: string;
    skin_grafting_rate_variation: string;
    skin_grafting_rate_cover_rate: string;
    ndvix_average: string;
    ndvix_variation: string;
    ndvix_cover_rate: string;
    visual_path: string;
    ndvi_path: string;
    skin_grafting_rate_path: string;
    ndvix_path: string;
    error_code: string;
    analysis_result_data: string;
    inspection_result_data: string;
    update_flag: string;

    // internal valiable
    processKind: string;
}
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/*
export const environment = {
    production: false,
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_MBswHlY9A',
    clientId: 'ecij0jmjf25aqrk8n4bjdgcj7'
};
*/
export const environment = {
    production: false,
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_wsRsEmj95',
    clientId: '2pajk7c101es9502aegudtl1g2'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails
} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk';

@Injectable({
    providedIn: 'root'
})

export class CognitoService {

    private userPool: CognitoUserPool;
    private poolData: any;
    public cognitoCreds: AWS.CognitoIdentityCredentials;
    private token: string;

    // Constructor
    constructor() {
        AWS.config.region = environment.region;
        this.poolData = { UserPoolId: environment.userPoolId, ClientId: environment.clientId };
        this.userPool = new CognitoUserPool(this.poolData);
    }

    // Login Function
    login(username: string, password: string): Promise<any> {
        const userData = {
            Username: username,
            Pool: this.userPool,
            Storage: localStorage
        };
        const cognitoUser = new CognitoUser(userData);
        const authenticationData = {
            Username: username,
            Password: password,
        };

        const authenticationDetails = new AuthenticationDetails(authenticationData);
        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function (result) {
                    console.log('id token + ' + result.getIdToken().getJwtToken());
                    console.log('access token + ' + result.getAccessToken().getJwtToken());
                    console.log('refresh token + ' + result.getRefreshToken().getToken());
                    resolve(result);
                },
                onFailure: function (err) {
                    console.log(err);
                    reject(err);
                }
            });
        });
    }

    // Check logined Function
    isAuthenticated(): Promise<any> {
        const cognitoUser = this.userPool.getCurrentUser();
        return new Promise((resolve, reject) => {
            if (cognitoUser === null) { reject(cognitoUser); }
            cognitoUser.getSession((err, session) => {
                if (err) {
                    reject(err);
                } else {
                    if (!session.isValid()) {
                        reject(session);
                    } else {
                        resolve(session);
                    }
                }
            });
        });
    }

    // Get token Function
    getCurrentUserIdToken(): any {
        const cognitoUser = this.userPool.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    return;
                } else {
                    this.token = session.getIdToken().getJwtToken();
                }
            });
        }
        return this.token;
    }

    // logout Function
    logout(): Promise<any> {
        return new Promise((resolve) => {
            const currentUser = this.userPool.getCurrentUser();
            console.dir(currentUser);
            if (currentUser) {
                currentUser.signOut();
            }
            resolve();            
        });
    }

    // Reset Password Function
    resetPassword(username: string): Promise<any> {
        const userData = {
            Username: username,
            Pool: this.userPool,
            Storage: localStorage
        };

        const cognitoUser = new CognitoUser(userData);
        return new Promise((resolve, reject) => {
            cognitoUser.forgotPassword({
                onSuccess: function (result) {
                    // successfully initiated reset password request
                    console.log('CodeDeliveryData from forgotPassword: ' + result);
                    resolve(result);
                },
                onFailure: function(err) {
                    console.log(err);
                    reject(err);
                }
            });
        })
    }

    // Change Password Function
    changePassword(username: string, newPassword: string, code: string): Promise<any> {
        const userData = {
            Username: username,
            Pool: this.userPool,
            Storage: localStorage
        };

        const password = newPassword;
        const verificationCode = code;

        const cognitoUser = new CognitoUser(userData);
        return new Promise((resolve, reject) => {
            cognitoUser.confirmPassword(verificationCode, password, {
                onSuccess() {
                    // successfully initiated reset password request
                    console.log('cognit.service Password confirmed!');
                    resolve("result");
                },
                onFailure(err) {
                    console.log('cognit.service Password error!');
                    reject(err);
                }
            });
        })
    }
}

export class InspectionDetailConst {
    public static readonly OWNER_ID             = "オーナーID";
    public static readonly INDICATE_ID          = "指示ID";
    public static readonly FARM_ID              = "圃場ID";
    public static readonly RECEPTION_DATE       = "検品受付日時";
    public static readonly INSPECTION_STATUS    = "圃場ID進捗";
    public static readonly INSPECTION_RESULT    = "検品結果";
    public static readonly INSPECTION_PERSONNEL = "検品担当者";
    public static readonly COMMENT              = "備考";
    public static readonly FE_INFOMATION        = "エラーコード";
    public static readonly UPDATE_BTN           = "更新";
    public static readonly VISUAL               = "可視";
    public static readonly NDVI                 = "NDVI（葉色）";
    public static readonly SKIN_GRAFTING_RATE   = "植被率（茎数）";
    public static readonly NDVIX                = "NDVIx植被率";
    public static readonly AVERAGE              = "平均：";
    public static readonly VARIATION            = "ばらつき：";
    public static readonly COVER_RATE           = "カバー率：";

    public static readonly INSPECTION_TARGET    = "検品対象";
    public static readonly ANALYTICAL_RESULT    = "解析結果";

    public static readonly OK_BTN               = "OK";
    public static readonly PENDING_BTN          = "保留";
    public static readonly NG_BTN               = "NG";
    public static readonly TOFIX_BTN            = "要修正";

}
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { ExecuteResult } from '../../model/execute-result';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CognitoService } from '../../service/cognito.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    displayFlag:boolean = false;
    private confirmLoginResult: ExecuteResult;
    private LogoutResult: ExecuteResult;
    public username: string;
    public role: string;
    private subscription: Subscription;

    constructor(private router: Router, private loginservice: LoginService, private cognito: CognitoService) {
        console.log("[[HeaderComponent]]--------------constructor() -----------");
        this.cognito.isAuthenticated()
        .then((res) => {
            this.username = res["idToken"]["payload"]["cognito:username"];
            this.role = res["idToken"]["payload"]["custom:role"];
            this.displayFlag = true;
        })
        .catch((err) => {
            this.displayFlag = false;
        });
    }

    ngOnInit() {
        console.log("[[HeaderComponent]]--------------ngOnInit() -----------");
        this.subscription = this.loginservice.username$.subscribe(
            msg => {
                this.username = msg;
            }
        );
        this.subscription = this.loginservice.role$.subscribe(
            msg => {
                this.role = msg;
                this.displayFlag = true;
            }
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    logout(){
        console.log("[[HeaderComponent]]--------------logout() -----------");
        this.loginservice.logout()
        .then((result) => {
        }).catch((err) => {
        });
    }
}

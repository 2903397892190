import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent }       from './component/login/login.component';
import { InspectionListComponent }  from './component/inspection-list/inspection-list.component';
import { DeliveryListComponent }    from './component/delivery-list/delivery-list.component';
import { ChangepassComponent }    from './component/changepass/changepass.component';

const routes: Routes = [
  { path:'', redirectTo: '/login', pathMatch: 'full' },
  { path:'',           component:LoginComponent},
  { path:'inspection', component:InspectionListComponent},
  { path:'delivery',   component:DeliveryListComponent},
  { path:'password', component: ChangepassComponent },
  { path: 'login', component: LoginComponent, pathMatch: 'full'},
  { path: '**', component: LoginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export class InspectionListConst {
  public static readonly TITTLE                = "検品・納品システム";
  public static readonly TAB_TITTLE_INSPECTION = "検品・画像修正一覧";
  public static readonly TAB_TITTLE_DELIVERY   = "納品一覧";

  public static readonly OWNER_ID                              = "オーナーID";
  public static readonly INDICATE_ID                           = "指示ID";
  public static readonly RECEPTION_DATE_FROM                   = "検品受付日時(from)";
  public static readonly RECEPTION_DATE_TO                     = "検品受付日時(to)";
  public static readonly INSPECTION_STATUS                     = "圃場ID進捗";
  public static readonly INSPECTION_STATUS_ANALYSIS            = "解析中";
  public static readonly INSPECTION_STATUS_ANALYSIS_ERROR      = "解析エラー";
  public static readonly INSPECTION_STATUS_INSPECTION_WAIT     = "検品待ち";
  public static readonly INSPECTION_STATUS_MODIFY_IMAGE_WAIT   = "修正待ち";
  public static readonly INSPECTION_STATUS_INSPECTION_COMPLETE = "検品済み";
  public static readonly INSPECTION_STATUS_STOP                = "中止";
  public static readonly INSPECTION_STATUS_DELIVERY_ERROR      = "納品エラー";
  public static readonly INSPECTION_RESULT                     = "検品結果";
  public static readonly INSPECTION_RESULT_NOT_INSPECTION      = "未検品";
  public static readonly INSPECTION_RESULT_PENDING             = "保留";
  public static readonly INSPECTION_RESULT_NECESSARY_MODIFY    = "要修正";
  public static readonly INSPECTION_RESULT_OK                  = "OK";
  public static readonly INSPECTION_RESULT_NG                  = "NG";
  public static readonly INSPECTION_PERSONNEL                  = "検品担当者";
  public static readonly COMMENT                               = "備考";
  public static readonly CLEAR_BTN                             = "クリア";
  public static readonly SEARCH_BTN                            = "検索";

  public static readonly SEARCH_RESULT      = "検索結果：";
  public static readonly SERACH_RESULT_UNIT = "件";
  public static readonly PAGE               = "件";

  public static readonly OWNER_ID_LISTHEADER             = "オーナーID";
  public static readonly INDICATE_ID_LISTHEADER          = "指示ID";
  public static readonly FARM_ID_LISTHEADER              = "圃場ID";
  public static readonly RECEPTION_DATETIME_LISTHEADER   = "検品受付日時";
  public static readonly INSPECTION_STATUS_LISTHEADER    = "圃場ID進捗";
  public static readonly INSPECTION_RESULT_LISTHEADER    = "検品結果";
  public static readonly INSPECTION_PERSONNEL_LISTHEADER = "検品担当者";
  public static readonly COMMENT_LISTHEADER              = "備考";

  public static readonly SEARCH_CONDITION                = "絞り込み";

}
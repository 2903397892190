export class DeliveryListConst {
    public static readonly TITTLE                        = "検品・納品システム";
    public static readonly TAB_TITTLE_INSPECTION         = "検品・画像修正一覧";
    public static readonly TAB_TITTLE_DELIVERY           = "納品一覧";

    public static readonly OWNER_ID                      = "オーナーID";
    public static readonly INDICATE_ID                   = "指示ID";
    public static readonly RECEPTION_DATE_FROM           = "解析受付日時(from)";
    public static readonly RECEPTION_DATE_TO             = "解析受付日時(to)";
    public static readonly STATUS                        = "指示ID進捗";

    public static readonly STATUS_DELIBERY_ERROR         = "納品エラーあり";
    public static readonly STATUS_NOINSPECTION_EXIST     = "未検品あり";
    public static readonly STATUS_DELIBERY_ENABLE        = "納品可能";
    public static readonly STATUS_DELIBERY_COMPLETE      = "納品完了";
    public static readonly STATUS_STOP                   = "中止";

    public static readonly DELIVERY_PERSONNEL            = "納品担当者";
    public static readonly DELIVERY_COMMENT              = "納品コメント";

    public static readonly CLEAR_BTN                     = "クリア";
    public static readonly SEARCH_BTN                    = "検索";

    public static readonly SEARCH_RESULT                 = "検索結果：";
    public static readonly RED_CHARACTER                 = "赤文字は解析受付日時から36時間経過後も指示ID進捗が「納品可能」または「未検品」";
    public static readonly SERACH_RESULT_UNIT            = "件";
    public static readonly PAGE                          = "件";

    public static readonly OWNER_ID_LISTHEADER           = "オーナーID";
    public static readonly INDICATE_ID_LISTHEADER        = "指示ID";
    public static readonly PRODUCT_KIND_LISTHEADER       = "作物";
    public static readonly RECEPTION_DATETIME_LISTHEADER = "解析受付日時";
    public static readonly STATUS_LISTHEADER             = "指示ID進捗";
    public static readonly DELIVERY_PERSONNEL_LISTHEADER = "納品担当者";
    public static readonly DELIVERY_COMMENT_LISTHEADER   = "納品コメント";

    public static readonly SEARCH_CONDITION              = "絞り込み";
}